import { AppRegistry, Platform } from 'react-native';
import { enableScreens } from 'react-native-screens';

require('react-native-gesture-handler');
enableScreens();

const App = require('./App').default;

AppRegistry.registerComponent('OolioKDS', () => App);

if (Platform.OS === 'web') {
  AppRegistry.runApplication('OolioKDS', {
    rootTag: document.getElementById('root'),
  });
}

const getTracingLocalStorageData = async () => {
  return {};
};
export { App };
