import { ApolloError } from '@apollo/client';
import { DateTime } from '@oolio-group/domain';
import { useEffect, useMemo, useState } from 'react';
import { useLazyQuery } from '@apollo/client/react/hooks';
import { getItem, setItem } from '../storage/interface';
import { GET_ORG_TIME_QUERY } from '../graphql/orgDateTime';
import { ORG_TIMINGS_STORAGE_KEY } from '../common/constants';
import { noopHandler } from '../utils/general';

export interface UseOrgTimingsProps {
  loading: boolean;
  error: string | undefined;
  dateTime?: DateTime;
  getOrgTimings: () => void;
}

export function useOrgTimings(): UseOrgTimingsProps {
  const [dateTime, setDateTime] = useState<DateTime>();

  const getOrgTimingFromStorage = async () => {
    const dateTime = (await getItem(ORG_TIMINGS_STORAGE_KEY)) as DateTime;
    setDateTime(dateTime);
    return dateTime;
  };

  const [getOrgTimings, getOrgTimingsResponse] = useLazyQuery(
    GET_ORG_TIME_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: data => {
        if (data?.dateTime) {
          setItem(ORG_TIMINGS_STORAGE_KEY, data.dateTime);
        }
      },
      onError: noopHandler,
    },
  );

  useEffect(() => {
    getOrgTimingFromStorage();
  }, []);

  const loading: boolean = getOrgTimingsResponse.loading;

  const error: ApolloError | undefined = getOrgTimingsResponse.error;

  return useMemo(
    () => ({
      loading,
      error: error ? (error as ApolloError)?.message : '',
      dateTime,
      getOrgTimings,
    }),
    [loading, error, dateTime, getOrgTimings],
  );
}
