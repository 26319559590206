import React, { FC } from 'react';
import { View, Text, TouchableOpacity, ActivityIndicator } from 'react-native';
import SettingsItemStyles from './SettingsItem.styles';
import theme from '../../common/theme';
import Icon from '../Icon/Icon';

export interface SettingsItemProps {
  type: 'text' | 'switch' | 'select' | 'parent' | 'action';
  title: string;
  testID: string;
  subtitle?: string;
  loading?: boolean;
  checked?: boolean;
  onChange?: () => void;
}

const SettingsItem: FC<SettingsItemProps> = ({
  type,
  title,
  testID,
  subtitle,
  loading,
  checked,
  onChange,
}) => {
  const styles = SettingsItemStyles();

  return (
    <TouchableOpacity
      testID={testID}
      disabled={loading}
      onPress={onChange}
      style={styles.container}
    >
      <Text style={styles.title}>{title}</Text>
      <View style={styles.accessory}>
        {subtitle ? (
          <Text numberOfLines={1} style={styles.subtitle}>
            {subtitle.length > 36
              ? `${subtitle.substring(0, 36)}...`
              : subtitle}
          </Text>
        ) : null}
        {type === 'select' && checked && !loading ? (
          <Icon name="check" size={24} color={theme.colors.green} />
        ) : type === 'switch' && !loading ? (
          <Icon
            size={24}
            color={checked ? theme.colors.green : theme.colors.grey5}
            name={`toggle-${checked ? 'on' : 'off'}`}
          />
        ) : type === 'parent' && !loading ? (
          <Icon
            size={24}
            color={theme.colors.grey5}
            name={`angle-${checked ? 'up' : 'down'}`}
          />
        ) : type === 'action' && !loading ? (
          <Icon size={24} color={theme.colors.grey5} name={'angle-right'} />
        ) : loading ? (
          <ActivityIndicator color={theme.colors.white} />
        ) : null}
      </View>
    </TouchableOpacity>
  );
};

export default SettingsItem;
