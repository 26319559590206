import { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { useColors } from '../../hooks/useColors';
import { useScale } from '../../hooks/useScale';
import theme from '../../common/theme';

const SortingOptionsStyles = () => {
  const { colors } = useColors();
  const { scale } = useScale();

  return useMemo(
    () =>
      StyleSheet.create({
        resetText: {
          color: colors.blue,
          fontFamily: theme.fonts.regular,
          padding: scale(10),
        },
        subTitleText: {
          color: colors.grey5,
          fontSize: scale(14),
          fontFamily: theme.fonts.medium,
          marginVertical: scale(10),
          height: scale(40),
          backgroundColor: colors.elevation1,
          borderRadius: theme.radius.m,
          paddingHorizontal: scale(14),
          paddingVertical: scale(12),
        },
      }),
    [colors, scale],
  );
};

export default SortingOptionsStyles;
