import { useCallback, useMemo, useRef, useState } from 'react';
import { DELAY_TO_BUMP } from '../common/constants';
import { useTimeout } from '../hooks/useTimeout';

export interface UseInvokeWithDelayProps {
  callBack: () => Promise<void>;
}

export function useInvokeWithDelay({ callBack }: UseInvokeWithDelayProps) {
  const invokeBump = useRef(true);
  const [showLoader, setShowLoader] = useState(false);

  const invokeFunctionWithDelay = useTimeout(async () => {
    if (invokeBump.current) {
      callBack && (await callBack());
      setShowLoader(false);
    }
  });
  const invokeFunction = useCallback(() => {
    if (!showLoader) {
      invokeBump.current = true;
      invokeFunctionWithDelay.start(DELAY_TO_BUMP);
      setShowLoader(true);
    } else {
      invokeBump.current = false;
      setShowLoader(false);
    }
  }, [invokeFunctionWithDelay, showLoader]);

  return useMemo(
    () => ({
      loading: showLoader,
      invokeFunction: invokeFunction,
    }),
    [invokeFunction, showLoader],
  );
}
