import { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { useScale } from '../../../hooks/useScale';
import { useColors } from '../../../hooks/useColors';
import theme from '../../../common/theme';

const OrderMessageModalStyles = () => {
  const { scale } = useScale();
  const { colors } = useColors();

  return useMemo(
    () =>
      StyleSheet.create({
        background: {
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: theme.colors.modalBg,
        },
        container: {
          padding: scale(30),
          overflow: 'hidden',
          borderRadius: theme.radius.l,
          height: scale(402),
          width: scale(600),
          backgroundColor: colors.elevation0,
        },
        title: {
          color: colors.text,
          fontSize: scale(16),
          lineHeight: scale(16),
          paddingTop: scale(20),
          paddingLeft: scale(4),
          fontFamily: theme.fonts.medium,
        },
        subTitle: {
          color: colors.textLight,
          fontSize: scale(14),
          lineHeight: scale(14),
          paddingTop: scale(20),
          paddingBottom: scale(14),
          fontFamily: theme.fonts.medium,
        },
        section: {
          width: scale(540),
          marginTop: scale(20),
          alignSelf: 'center',
        },
        input: {
          width: '100%',
          height: scale(100),
          borderRadius: theme.radius.s,
          backgroundColor: colors.background,
          color: colors.text,
          padding: scale(10),
        },
        action: {
          width: '100%',
          height: scale(44),
          flexDirection: 'row',
          alignSelf: 'stretch',
          justifyContent: 'flex-end',
        },
        btn: {
          width: scale(100),
          height: scale(44),
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: colors.background,
          borderRadius: theme.radius.s,
          marginLeft: 10,
        },
        btnText: {
          fontSize: scale(14),
          lineHeight: scale(20),
          color: colors.text,
        },
      }),
    [colors, scale],
  );
};

export default OrderMessageModalStyles;
