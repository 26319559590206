import { useCallback, useContext } from 'react';
import {
  getDockets,
  saveBumpedDocket,
  saveRecalledDocket,
  saveDocketsOverwrite,
  saveDockets,
  swapUpdatedDockets,
  getAllDockets,
} from '../storage/docket';
import config from '../config';
import {
  Docket,
  DocketItemStatus,
  DocketStatus,
  OrderType,
} from '@oolio-group/domain';
import { DocketSource } from '../common/types';
import { useOrgTimings } from './useOrgTimings';
import { getStartTimeandEndTime } from '../utils/timeHelper';
import { DocketBumper } from '../screens/Main/Dockets/DocketBumper';
import { makeRequest } from '../utils/fetchHelper';
import { openDocketslastUpdatedAt } from '../state/apolloVars';
import { useOrderNotification } from './useOrderNotification';
import { useSession } from './useSession';
import { usePrintingWithTemplate } from './printing/usePrintingWithTemplate';
import { sendNotification } from './useNotification';
import { generatePrinterOptions } from './printing/generatePrinterOption';
import { useSalesChannels } from './useSalesChannels';
import { useOrderTypes } from './useOrderTypes';

export interface UseDockets {
  getActiveDockets: (
    printerProfileIds: string[],
    storeId: string,
  ) => Promise<Docket[]>;
  getCompletedDockets: (
    printerProfileIds: string[],
    storeId: string,
  ) => Promise<Docket[]>;
  getDockets: (type: DocketStatus) => Promise<Docket[]>;
  bumpDocketWithStatus: (
    id: string,
    status: DocketItemStatus,
  ) => Promise<Docket>;
  recallDocket: (id: string) => Promise<Docket>;
  bumpDocketItem: (id: string) => Promise<Docket>;
  bumpDocketItems: (ids: string[]) => Promise<void>;
  serveDocketItem: (id: string) => Promise<Docket>;
  reBumpDocketItem: (id: string) => Promise<Docket>;
  recallDocketItem: (id: string) => Promise<Docket>;
  printKitchenDocket: (docket: string, status: DocketStatus) => Promise<void>;
  ackDocketsReceived: (dockets?: Docket[]) => Promise<void>;
}

export function useDockets(): UseDockets {
  const { dateTime } = useOrgTimings();
  const context = useContext(DocketBumper);
  const { sendBumpSmsNotification, sendReceiveSmsNotification } =
    useOrderNotification();
  const { session } = useSession();
  const { notifyOnBump, notifyOnReceive } =
    session.kitchenDisplay?.notificationConfig || {};

  const { printKitchenDocket } = usePrintingWithTemplate();

  const { salesChannels } = useSalesChannels();
  const { orderTypes } = useOrderTypes();

  const ackDocketsReceived = useCallback(
    async (dockets: Docket[] = []) => {
      const receivedBy = session.kitchenDisplay?.id || '';

      const docketsToAck = dockets.filter(
        docket =>
          !docket?.receivedAcks?.find(ack => ack.receivedBy === receivedBy),
      );

      if (
        docketsToAck.length &&
        session.kitchenDisplay?.id &&
        session.kitchenDisplay?.name
      ) {
        const input = {
          ids: docketsToAck.map(docket => docket.id),
          receivedBy: session.kitchenDisplay.id || '',
          receivedByName: session.kitchenDisplay?.name || '',
        };

        return await makeRequest(
          config.KDS_SERVICE_URI + '/dockets/received',
          input,
        );
      }
    },
    [session.kitchenDisplay?.name, session.kitchenDisplay?.id],
  );

  const getDocketsByPrinterProfileAndStatus = useCallback(
    //TODO add store id in route or in headers
    async (ids: string[], status: DocketStatus, storeId: string) => {
      const timings = getStartTimeandEndTime(dateTime?.startTime || '');
      const input = {
        ids,
        status: status,
        ...(status == DocketStatus.COMPLETED && {
          startTime: timings.startTime,
          endTime: timings.endTime,
        }),
        ...(storeId && { storeId }),
      };
      const data = await makeRequest(
        config.KDS_SERVICE_URI + '/dockets',
        input,
      );
      const dockets = data.dockets;
      if (dockets?.length) {
        await saveDocketsOverwrite(dockets, status, DocketSource.API);
        return dockets as Docket[];
      }
      return [] as Docket[];
    },
    [dateTime?.startTime],
  );
  const getActiveDockets = useCallback(
    async (printerProfileIds: string[], storeId: string) => {
      const dockets = await getDocketsByPrinterProfileAndStatus(
        printerProfileIds,
        DocketStatus.CREATED,
        storeId,
      );
      if (notifyOnReceive) {
        const unNotifiedDockets = dockets.filter(
          d => !d?.notificationStatus?.smsOnReceive,
        );
        if (unNotifiedDockets?.length) {
          sendReceiveSmsNotification(unNotifiedDockets.map(item => item.id));
        }
      }
      return dockets;
    },
    [
      getDocketsByPrinterProfileAndStatus,
      notifyOnReceive,
      sendReceiveSmsNotification,
    ],
  );

  const getCompletedDockets = useCallback(
    async (printerProfileIds: string[], storeId: string) => {
      return await getDocketsByPrinterProfileAndStatus(
        printerProfileIds,
        DocketStatus.COMPLETED,
        storeId,
      );
    },
    [getDocketsByPrinterProfileAndStatus],
  );

  const getSalesChannelName = useCallback(
    (docket: Docket | undefined): string => {
      const salesChannelId = docket?.salesChannel?.id;
      const salesChannel =
        salesChannels.find(s => s.id === salesChannelId)?.name || '';
      return salesChannel;
    },
    [salesChannels],
  );

  const getOrderTypeFromId = useCallback(
    (id: string | undefined): OrderType | undefined => {
      if (!id) {
        return undefined;
      }
      return orderTypes.find(o => o.id === id) as OrderType;
    },
    [orderTypes],
  );

  const printDocket = useCallback(
    async (docket?: Docket) => {
      const newDocket = {
        ...docket,
        ...(docket?.salesChannel && {
          salesChannel: {
            ...docket.salesChannel,
            name: getSalesChannelName(docket),
          },
        }),
        ...(docket?.orderType?.id && {
          orderType: {
            id: docket.orderType.id,
            ...getOrderTypeFromId(docket.orderType.id),
          },
        }),
      } as Docket;
      const printer = session?.kitchenDisplay?.printer;
      if (newDocket && printer) {
        const result = await printKitchenDocket(
          newDocket,
          generatePrinterOptions(
            printer,
            session?.kitchenDisplay?.singleItemPrinting || false,
          ),
        );
        if (result) {
          sendNotification(result);
        }
      }
    },
    [
      getOrderTypeFromId,
      getSalesChannelName,
      printKitchenDocket,
      session?.kitchenDisplay?.printer,
      session?.kitchenDisplay?.singleItemPrinting,
    ],
  );

  const printDocketById = useCallback(
    async (docketId: string, status: DocketStatus) => {
      const dockets = await getDockets(status);
      const docket = dockets.find(d => d.id === docketId);
      if (docket) {
        await printDocket(docket);
      } else {
        //if docket is not found by status, try to get docket by id
        const allDockets = await getAllDockets();
        const docket = allDockets.find(d => d?.id === docketId);
        if (docket) {
          await printDocket(docket);
        }
      }
    },
    [printDocket],
  );

  const bumpDocketWithStatus = useCallback(
    async (docketId: string, status: DocketItemStatus) => {
      const input = {
        ids: [docketId],
        status: status,
      } as { ids: string[] };
      const dockets = await makeRequest(
        config.KDS_SERVICE_URI + '/docket/bump',
        input,
      );
      if (session?.kitchenDisplay?.autoPrintOnCompletion === true) {
        if (dockets?.[0]) {
          await printDocket(dockets[0]);
        } else {
          await printDocketById(docketId, DocketStatus.CREATED);
        }
      }
      if (
        notifyOnBump &&
        dockets?.length &&
        !dockets?.[0].notificationStatus?.smsOnBump
      ) {
        sendBumpSmsNotification([docketId]);
      }
      if (dockets?.length) {
        saveBumpedDocket(dockets[0]);
        if (context.current)
          context.current = context.current.filter(c => c != dockets[0].id);
        openDocketslastUpdatedAt(performance.now());
        return dockets[0] as Docket;
      }
      return {} as Docket;
    },
    [
      context,
      notifyOnBump,
      printDocket,
      printDocketById,
      sendBumpSmsNotification,
      session?.kitchenDisplay?.autoPrintOnCompletion,
    ],
  );

  const bumpDocketItem = useCallback(async (docketItemId: string) => {
    const input = {
      id: docketItemId,
    } as { id: string };
    const dockets = await makeRequest(
      config.KDS_SERVICE_URI + '/docket-item/bump',
      input,
    );
    if (dockets?.length) {
      saveDockets(docketItemId, dockets, DocketSource.API);
      openDocketslastUpdatedAt(performance.now());
      return dockets;
    }
    return {} as Docket;
  }, []);

  const bumpDocketItems = useCallback(async (docketItemIds: string[]) => {
    const input = {
      ids: docketItemIds,
    } as { ids: string[] };
    const dockets = await makeRequest(
      config.KDS_SERVICE_URI + '/docket-items/bump',
      input,
    );
    if (dockets?.length) {
      await swapUpdatedDockets(dockets, DocketSource.API);

      openDocketslastUpdatedAt(performance.now());
    }
  }, []);

  const serveDocketItem = useCallback(async (docketItemId: string) => {
    const input = {
      id: docketItemId,
    } as { id: string };
    const dockets = await makeRequest(
      config.KDS_SERVICE_URI + '/docket-item/serve',
      input,
    );
    if (dockets?.length) {
      saveDockets(docketItemId, dockets, DocketSource.API);
      openDocketslastUpdatedAt(performance.now());
      return dockets;
    }
    return {} as Docket;
  }, []);

  const reBumpDocketItem = useCallback(async (docketItemId: string) => {
    const input = {
      id: docketItemId,
    } as { id: string };
    const dockets = await makeRequest(
      config.KDS_SERVICE_URI + '/docket-item/rebump',
      input,
    );
    if (dockets?.length) {
      saveDockets(docketItemId, dockets, DocketSource.API);
      openDocketslastUpdatedAt(performance.now());
      return dockets;
    }
    return {} as Docket;
  }, []);

  const recallDocketItem = useCallback(async (docketItemId: string) => {
    const input = {
      id: docketItemId,
    } as { id: string };
    const dockets = await makeRequest(
      config.KDS_SERVICE_URI + '/docket-item/recall',
      input,
    );
    if (dockets?.length) {
      saveRecalledDocket(dockets[0]);
      openDocketslastUpdatedAt(performance.now());
      return dockets;
    }
    return {} as Docket;
  }, []);

  const recallDocket = useCallback(async (docketId: string) => {
    const input = {
      id: docketId,
    } as { id: string };
    const docket = await makeRequest(
      config.KDS_SERVICE_URI + '/docket/recall',
      input,
    );
    if (docket?.id) {
      saveRecalledDocket(docket);
      openDocketslastUpdatedAt(performance.now());

      return docket as Docket;
    }
    return {} as Docket;
  }, []);

  return {
    getActiveDockets,
    getCompletedDockets,
    getDockets,
    bumpDocketWithStatus,
    recallDocket,
    bumpDocketItem,
    bumpDocketItems,
    recallDocketItem,
    serveDocketItem,
    reBumpDocketItem,
    printKitchenDocket: printDocketById,
    ackDocketsReceived,
  };
}
