import { gql } from '@apollo/client';

export const GET_ORG_TIME_QUERY = gql`
  query getDateTime {
    dateTime {
      startTime
      startDay
      startMonth
    }
  }
`;
