import processRequest from './processRequest';
import { addToQueue, shiftQueue, hasItemsInQueue } from './PrintQueue';

let workerIsRunning = false;

// listen for messages
onmessage = payload => {
  // If worker is currently running, push request to queue
  // Otherwise, execute the worker
  if (workerIsRunning) {
    addToQueue(payload);
  } else {
    workerIsRunning = true;
    runWorker(payload);
  }
};

// Runs worker on queued request
const runWorker = async payload => {
  try {
    const message = JSON.parse(payload.data);
    const results = await processRequest(message);
    results?.forEach(result => {
      // send message
      postMessage(
        JSON.stringify({
          ...result,
          message: `${message.data.errorMessage} `,
          requestId: message.requestId,
          timestamp: Date.now(),
          action: message.action,
        }),
      );
    });
  } catch (error) {
    postMessage(
      JSON.stringify({
        status: 'ERROR',
        message: error.toString(),
        timestamp: Date.now(),
      }),
    );
  }

  if (hasItemsInQueue()) {
    // run worker for next request
    runWorker(shiftQueue());
  } else {
    workerIsRunning = false;
  }
};
