import { startCase } from 'lodash';
import React from 'react';
import { View, Text } from 'react-native';
import DocketItemStyles from '../DocketItem.styles';
export interface DocketItemBaseProps {
  name: string;
}

const DocketItemHeader: React.FC<DocketItemBaseProps> = ({ name }) => {
  const styles = DocketItemStyles();

  return (
    <View
      testID="docket-course"
      style={[styles.statusContainer, styles.courseContainer]}
    >
      <Text style={[styles.statusText, styles.courseText]}>
        {name ? `– ${startCase(name?.toLowerCase())} –` : ''}
      </Text>
    </View>
  );
};

export default React.memo(DocketItemHeader);
