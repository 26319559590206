import React from 'react';
import { ViewStyle } from 'react-native';
import * as Unicons from '@oolio-group/react-native-unicons';
import { startCase } from 'lodash';
import { useScale } from '../../hooks/useScale';
import theme from '../../common/theme';

export interface IconProps {
  name: string;
  size?: number;
  color?: string;
  testID?: string;
  style?: ViewStyle;
}

const Icon: React.FC<IconProps> = ({ name, size, color, style }) => {
  const { scale } = useScale();
  const resized = scale(size || 20);

  const Component = Unicons['Uil' + startCase(name).replace(/\s/g, '')];

  if (!Component) {
    return null;
  }

  return (
    <Component
      color={color}
      size={resized}
      style={[
        {
          width: resized,
          color: color || theme.colors.black,
        },
        style,
      ]}
    />
  );
};

export default Icon;
