import React, { useCallback, useContext, useEffect } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { DocketStatus } from '@oolio-group/domain';
import BumpItemIndicator from './BumpItemIndicator';
import DocketItemTimer from './DocketItemTimer';
import { useInvokeWithDelay } from '../../../hooks/useInvokeWithDelay';
import { DocketBumper } from '../../../screens/Main/Dockets/DocketBumper';
import theme from '../../../common/theme';
import DocketItemStyles from '../DocketItem.styles';
import { useColors } from '../../../hooks/useColors';
import Icon from '../../Icon/Icon';

export interface DocketItemHeaderProps {
  docketId: string;
  orderTypeName: string;
  salesChannelName: string;
  orderNumber: string;
  docketName: string;
  createdTime: string;
  onPressDocketHeader: () => Promise<void>;
  status: DocketStatus;
  docketHeaderColor?: string;
  docketStatus: DocketStatus;
  enablePrint?: boolean;
  isTraining?: boolean;
  printKitchenDocket?: (docket: string, status: DocketStatus) => Promise<void>;
}

const DocketItemHeader: React.FC<DocketItemHeaderProps> = ({
  docketId,
  orderTypeName,
  onPressDocketHeader,
  orderNumber,
  docketName,
  createdTime,
  status,
  docketHeaderColor = theme.colors.primary,
  docketStatus,
  salesChannelName,
  enablePrint,
  isTraining,
  printKitchenDocket,
}) => {
  const { colors } = useColors();
  const styles = DocketItemStyles();
  const context = useContext(DocketBumper);
  const { loading: bumpLoading, invokeFunction: invokeBump } =
    useInvokeWithDelay({ callBack: onPressDocketHeader });

  const handleOnInvokeBump = useCallback(() => {
    if (context.current && context.current?.includes(docketId)) {
      context.current = context?.current?.filter(id => id != docketId) || [];
    } else {
      context.current = [...(context?.current || []), docketId];
    }
    invokeBump();
  }, [context, docketId, invokeBump]);

  useEffect(() => {
    if (
      docketStatus == DocketStatus.CREATED &&
      context.current?.includes(docketId) &&
      !bumpLoading
    ) {
      invokeBump();
    }
  }, [bumpLoading, context, docketId, invokeBump, docketStatus, orderNumber]);

  const headerText = salesChannelName
    ? `${salesChannelName} - ${orderTypeName}`
    : orderTypeName;

  return (
    <TouchableOpacity
      testID="docket-header"
      onPress={handleOnInvokeBump}
      disabled={status == DocketStatus.COMPLETED}
      style={[
        !!enablePrint ? styles.headerPrintEnabled : styles.header,
        {
          backgroundColor:
            status == DocketStatus.CREATED ? docketHeaderColor : colors.green,
        },
        isTraining && styles.headerSpacingForTraining,
      ]}
    >
      {context?.current?.includes(docketId) && bumpLoading ? (
        <BumpItemIndicator />
      ) : (
        <>
          <View style={styles.headerContent}>
            <View style={styles.rowGroup}>
              <View style={styles.row}>
                <Text style={styles.text}>{headerText}</Text>
                <Text style={styles.text}>{orderNumber}</Text>
              </View>
              <View style={styles.row}>
                <Text numberOfLines={1} style={styles.text}>
                  {docketName}
                </Text>
                <DocketItemTimer createdTime={createdTime} status={status} />
              </View>
            </View>
            {!!enablePrint && (
              <TouchableOpacity
                testID="btn-settings"
                onPress={() => {
                  printKitchenDocket && printKitchenDocket(docketId, status);
                }}
                style={styles.printIconContainer}
              >
                <Icon name="print" color={colors.white} />
              </TouchableOpacity>
            )}
          </View>
        </>
      )}
    </TouchableOpacity>
  );
};

export default DocketItemHeader;
