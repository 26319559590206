import React, { useEffect } from 'react';
import { useNetInfo } from '@react-native-community/netinfo';
import { useReactiveVar } from '@apollo/client/react/hooks';
import { netWorkStatus } from '../../state/apolloVars';

export const useNetworkStatusVar = () => useReactiveVar(netWorkStatus);

const NetworkStatus = () => {
  const netInfo = useNetInfo();
  useEffect(() => {
    netWorkStatus(!!netInfo?.isConnected && !!netInfo?.isInternetReachable);
  }, [netInfo]);
  return <></>;
};

export default NetworkStatus;
