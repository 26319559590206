import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { ActivityIndicator, Text, TouchableOpacity, View } from 'react-native';
import Icon from '../Icon/Icon';
import { useColors } from '../../hooks/useColors';
import Sidebar from '../Sidebar/Sidebar';
import { useReactiveVar } from '@apollo/client';
import {
  allDayViewItemsVar,
  allDayViewHighlightedItemsVar,
} from '../../state/apolloVars';
import { AllDayViewItem } from '../../common/types';
import AllDayViewStyles from './AllDayView.styles';
import { formatQuantityString } from '../../utils/general';
import { useDockets } from '../../hooks/useDockets';
import { uniq } from 'lodash';
import { useTranslation } from '@oolio-group/localization';

interface AllDayViewProps {
  visible: boolean;
  onClose: () => void;
}

const AllDayView: React.FC<AllDayViewProps> = ({
  visible,
  onClose,
}: AllDayViewProps) => {
  const styles = AllDayViewStyles();
  const { translate } = useTranslation();
  const { colors } = useColors();
  const allDayViewItems = useReactiveVar(allDayViewItemsVar);
  const [selectedItems, setSelectedItems] = useState<Array<string>>([]);
  const { bumpDocketItems } = useDockets();
  const [isBumping, setIsBumping] = useState<boolean>(false);

  useEffect(() => {
    allDayViewHighlightedItemsVar(selectedItems);
  }, [selectedItems]);

  const onPressClear = useCallback(() => {
    setSelectedItems([]);
  }, []);

  const onPressBumpAll = useCallback(async () => {
    if (!selectedItems.length) return;
    setIsBumping(true);

    const orderItemIds = uniq(
      allDayViewItems
        .filter(x => selectedItems.includes(x?.docketItemIds?.[0] ?? ''))
        .flatMap(e => e.orderItemIds) as string[],
    );

    await bumpDocketItems(orderItemIds);

    setIsBumping(false);
  }, [allDayViewItems, bumpDocketItems, selectedItems]);

  const renderItem = useCallback(
    (item: AllDayViewItem) => {
      const isSelected = selectedItems.includes(item?.docketItemIds?.[0] ?? '');

      const onToggleSelection = () => {
        if (isSelected) {
          setSelectedItems(prev =>
            prev.filter(x => !(item?.docketItemIds ?? []).includes(x)),
          );
        } else {
          setSelectedItems(prev => prev.concat(item?.docketItemIds ?? []));
        }
      };

      return (
        <TouchableOpacity
          key={item.product}
          style={[styles.item, isSelected && styles.itemSelected]}
          onPress={onToggleSelection}
        >
          <View style={styles.innerRow}>
            <View style={styles.nameContainer}>
              {!!item?.course && (
                <Text numberOfLines={1} style={styles.courseText}>
                  {item?.course}
                </Text>
              )}
              <Text
                style={[styles.itemText, isSelected && styles.itemSelectedText]}
              >
                {item?.product}
              </Text>
            </View>

            <View style={styles.qtyContainer}>
              <Text
                style={[styles.itemText, isSelected && styles.itemSelectedText]}
              >
                {formatQuantityString(item?.quantity ?? 1)}
              </Text>
              {isSelected ? (
                <Icon name="check" size={20} color={colors.green} />
              ) : (
                <Icon name="angle-right" size={20} color={colors.grey5} />
              )}
            </View>
          </View>
          {!!item.modifiers?.length &&
            item.modifiers.map(modifier => (
              <Text
                key={modifier}
                style={[
                  styles.optionText,
                  { color: isSelected ? colors.textInverted : colors.text },
                ]}
              >
                {modifier}
              </Text>
            ))}
        </TouchableOpacity>
      );
    },
    [colors, selectedItems, styles],
  );

  const selectedItemCount = useMemo(() => {
    return !!selectedItems.length
      ? `(${allDayViewItems
          .filter(x => selectedItems.includes(x?.docketItemIds?.[0] ?? ''))
          .map(e => e.quantity)
          .reduce((sum, item) => (sum || 0) + (item || 0), 0)})`
      : '';
  }, [allDayViewItems, selectedItems]);

  const renderAllDayViewItems = useMemo(() => {
    return allDayViewItems.map(item => renderItem(item));
  }, [allDayViewItems, renderItem]);

  const renderActions = useMemo(() => {
    return (
      <View style={styles.action}>
        <TouchableOpacity
          style={[styles.btn, { backgroundColor: colors.blue }]}
          onPress={onPressClear}
          disabled={!selectedItems?.length}
        >
          <Text style={styles.btnText}>
            {translate('kitchenDisplay.clear')}
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[styles.btn, { backgroundColor: colors.orange }]}
          onPress={onPressBumpAll}
          disabled={!selectedItems?.length}
        >
          {isBumping ? (
            <ActivityIndicator color={colors.white} />
          ) : (
            <Text style={styles.btnText}>
              {translate('kitchenDisplay.bump')} {selectedItemCount}
            </Text>
          )}
        </TouchableOpacity>
      </View>
    );
  }, [
    styles,
    colors,
    onPressClear,
    selectedItems?.length,
    translate,
    onPressBumpAll,
    isBumping,
    selectedItemCount,
  ]);

  if (!visible) return null;

  return (
    <Sidebar
      onClose={onClose}
      title={translate('kitchenDisplay.allDayDisplay')}
      action={renderActions}
    >
      <>{renderAllDayViewItems}</>
    </Sidebar>
  );
};

export default AllDayView;
