import { useMemo } from 'react';
import { Dimensions, StyleSheet } from 'react-native';
import { useScale } from '../../../hooks/useScale';
import { useColors } from '../../../hooks/useColors';
import theme from '../../../common/theme';

const { height } = Dimensions.get('window');

const PrinterProfileModalStyles = () => {
  const { scale } = useScale();
  const { colors } = useColors();

  return useMemo(
    () =>
      StyleSheet.create({
        background: {
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: theme.colors.modalBg,
        },
        container: {
          padding: 30,
          overflow: 'hidden',
          borderRadius: theme.radius.l,
          maxHeight: height - scale(200),
          backgroundColor: colors.elevation0,
        },
        title: {
          color: colors.text,
          fontSize: scale(16),
          lineHeight: scale(16),
          paddingTop: scale(20),
          paddingLeft: scale(14),
          fontFamily: theme.fonts.medium,
        },
        listSection: {
          width: scale(540),
          flex: 1,
          marginTop: scale(20),
        },
        btnSection: {
          width: scale(540),
          marginTop: scale(20),
          height: scale(50),
        },
        profiles: {
          maxHeight: scale(450),
        },
      }),
    [colors, scale],
  );
};

export default PrinterProfileModalStyles;
