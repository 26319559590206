import { makeVar } from '@apollo/client';
import { DocketUpdate, AllDayViewItem } from '../common/types';
import { Session } from './preferences';
import BackgroundWorker from '../workers/BackgroundWorker';

export const docketsUpdated = makeVar<DocketUpdate>({ timestamp: 0 });
export const netWorkStatus = makeVar<boolean>(false);
export const tokenUpdated = makeVar<{ timestamp: number }>({ timestamp: 0 });
export const tokenState = makeVar<
  Pick<Session, 'token' | 'expiredDate' | 'refreshToken'>
>({});

export const openDocketslastUpdatedAt = makeVar<number>(0);

export const allDayViewItemsVar = makeVar<AllDayViewItem[]>([]);
export const allDayViewHighlightedItemsVar = makeVar<string[]>([]);
export const docketsCategoriesCount = makeVar<Record<string, number> | {}>({});

export const workerInstanceVar = makeVar<BackgroundWorker | undefined>(
  undefined,
);
