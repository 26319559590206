import { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useScale } from '../../hooks/useScale';
import { useColors } from '../../hooks/useColors';

const PaginationStyles = () => {
  const { scale } = useScale();
  const { colors } = useColors();
  const insets = useSafeAreaInsets();

  return useMemo(
    () =>
      StyleSheet.create({
        container: {
          padding: scale(16),
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: colors.elevation1,
          paddingBottom: scale(16 + insets.bottom),
        },
        pages: {
          flexDirection: 'row',
          alignItems: 'center',
          marginHorizontal: scale(20),
        },
      }),
    [colors, insets, scale],
  );
};

export default PaginationStyles;
