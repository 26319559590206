import React, { useRef, createContext, MutableRefObject } from 'react';

export const DocketBumper = createContext(
  null as unknown as MutableRefObject<string[] | null>,
);

export function DocketBumperProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const docketIdsRef = useRef<string[]>([]);
  return (
    <DocketBumper.Provider value={docketIdsRef}>
      {children}
    </DocketBumper.Provider>
  );
}
