import React from 'react';
import { View, Text, ActivityIndicator, StyleSheet } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import { useScale } from '../../../hooks/useScale';
import { useColors } from '../../../hooks/useColors';
import theme from '../../../common/theme';

const BumpItemIndicator: React.FC = () => {
  const { scale } = useScale();
  const { colors } = useColors();
  const { translate } = useTranslation();

  const styles = StyleSheet.create({
    container: {
      height: scale(44),
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    text: {
      fontSize: scale(16),
      marginLeft: scale(8),
      color: theme.colors.white,
      fontFamily: theme.fonts.medium,
    },
  });

  return (
    <View testID="item-completing" style={styles.container}>
      <ActivityIndicator size="small" color={colors.white} />
      <Text style={styles.text}>{translate('kitchenDisplay.Completing')}</Text>
    </View>
  );
};

export default BumpItemIndicator;
