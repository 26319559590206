/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloError } from '@apollo/client';
import {
  UpdateKitchenDisplayInput,
  PrinterProfileType,
  PrinterProfile,
  KitchenDisplay,
} from '@oolio-group/domain';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client/react/hooks';
import { useSession } from '../hooks/useSession';
import { stripProperties } from '@oolio-group/client-utils';
import pick from 'lodash.pick';
import { GET_PRINTER_PROFILES_QUERY } from '../graphql/printerProfile';
import { UPDATE_KITCHEN_DISPLAY } from '../graphql/kitchenDisplay';
import { noopHandler } from '../utils/general';
import {
  POSTHOG_EVENT_ACTIONS,
  POSTHOG_EVENT_DATA,
  analyticsService,
} from '../analytics/AnalyticsService';
export interface UsePrinterProfilesProps {
  loading: boolean;
  error: string | undefined;
  printerProfiles: PrinterProfile[];
  assignPrinterProfiles: (id: string[], callback?: Function) => void;
}

export function usePrinterProfiles(): UsePrinterProfilesProps {
  const [printerProfiles, setPrinterProfiles] = useState<PrinterProfile[]>([]);
  const { session, setSession } = useSession();
  const assignPrinterProfileCallback = useRef<Function | null>(null);
  const onCompleteGetPrinterProfilesRequest = useCallback((data: any) => {
    if (data?.printerProfiles) {
      const printerProfilesData = data.printerProfiles as PrinterProfile[];
      setPrinterProfiles(printerProfilesData);
    }
  }, []);

  const onCompleteAssignPrinterProfilesRequest = useCallback(
    (data: any) => {
      if (data) {
        const kdsData = data.updateKitchenDisplay as KitchenDisplay;
        const sessionKeysToUpdate = { kitchenDisplay: kdsData };
        assignPrinterProfileCallback?.current &&
          assignPrinterProfileCallback.current();
        setSession(sessionKeysToUpdate);
        analyticsService.capture(POSTHOG_EVENT_ACTIONS.UPDATE_PRINTER_PROFILE, {
          device: kdsData?.id,
          kdsName: kdsData?.name,
          printerProfiles: (kdsData?.printerProfiles || [])?.map(x => ({
            id: x.id,
            name: x.name,
          })),
          store: kdsData?.store?.id,
          orgId: session?.currentOrganization?.id,
          orgName: session?.currentOrganization?.name,
        } as POSTHOG_EVENT_DATA);
      }
    },
    [setSession, session],
  );

  const [getPrinterProfiles, getPrinterProfilesResponse] = useLazyQuery(
    GET_PRINTER_PROFILES_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      onCompleted: onCompleteGetPrinterProfilesRequest,
      onError: noopHandler,
      variables: {
        filter: {
          printerProfileType: PrinterProfileType.KITCHEN,
        },
      },
    },
  );

  const [updatePrinterProfilesForDevice, updateKitchenDisplayResponse] =
    useMutation(UPDATE_KITCHEN_DISPLAY, {
      onCompleted: onCompleteAssignPrinterProfilesRequest,
    });

  const assignPrinterProfiles = useCallback(
    (printerProfileIds: string[], callback?: Function) => {
      if (printerProfileIds.length) {
        const kitchenDisplay = pick(
          session?.kitchenDisplay,
          'id',
          'name',
          'printerProfiles',
          'isPaired',
          'store',
          'lastDisconnectedAt',
        );
        updatePrinterProfilesForDevice({
          variables: {
            input: {
              ...stripProperties(kitchenDisplay, '__typename'),
              printerProfiles: printerProfileIds,
              //TODO : to resolve to same type in response of the login or kitchenDocket
              store: kitchenDisplay?.store?.id || kitchenDisplay?.store,
            } as UpdateKitchenDisplayInput,
          },
        });
        if (callback) assignPrinterProfileCallback.current = callback;
      }
    },
    [session?.kitchenDisplay, updatePrinterProfilesForDevice],
  );

  useEffect(() => {
    getPrinterProfiles();
  }, [getPrinterProfiles]);

  const loading: boolean =
    getPrinterProfilesResponse.loading || updateKitchenDisplayResponse.loading;

  const error: ApolloError | undefined =
    getPrinterProfilesResponse.error || updateKitchenDisplayResponse.error;

  return useMemo(
    () => ({
      loading,
      error: error ? (error as ApolloError)?.message : '',
      printerProfiles,
      getPrinterProfiles,
      assignPrinterProfiles,
    }),
    [
      loading,
      error,
      printerProfiles,
      getPrinterProfiles,
      assignPrinterProfiles,
    ],
  );
}
