import React, { useCallback, useEffect, useState } from 'react';
import { View, Text, Modal, ScrollView } from 'react-native';
import { PrinterProfile } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { useFetchSettings } from '../../../hooks/useFetchSettings';
import SettingsItem from '../../SettingsItem/SettingsItem';
import SettingsAction from '../../SettingsItem/SettingsAction';
import PrinterProfileModalStyles from './PrinterProfileModal.styles';
import { analyticsService } from '../../../analytics/AnalyticsService';

export interface SettingModalProps {
  loading: boolean;
  visible: boolean;
  onClose?: () => void | Promise<void>;
  printerProfiles: PrinterProfile[];
  onSave: (id: string[]) => void;
}

const SettingModal: React.FC<SettingModalProps> = ({
  visible,
  onClose,
  printerProfiles,
  onSave,
  loading,
}) => {
  const { translate } = useTranslation();
  const styles = PrinterProfileModalStyles();
  const { fetchSettings } = useFetchSettings();
  const [printerProfileIds, setSelectedPrinterProfile] = useState<string[]>([]);

  useEffect(() => {
    fetchSettings();
  }, [fetchSettings]);

  const selectPrinterProfile = (printerProfileId: string) => {
    analyticsService.capture('Updated Printer Profile');
    setSelectedPrinterProfile(prev => {
      if (prev.includes(printerProfileId)) {
        return prev.filter(
          prevPrinterProfileId => prevPrinterProfileId !== printerProfileId,
        );
      } else return [...prev, printerProfileId];
    });
  };

  const onPressSave = useCallback(() => {
    if (printerProfileIds.length) onSave(printerProfileIds);
  }, [onSave, printerProfileIds]);

  return (
    <Modal
      animationType="fade"
      onDismiss={onClose}
      onRequestClose={onClose}
      presentationStyle="overFullScreen"
      transparent={true}
      visible={visible}
      supportedOrientations={['landscape', 'landscape-left', 'landscape-right']}
    >
      <View style={styles.background}>
        <View style={styles.container}>
          <Text style={styles.title}>
            {translate('kitchenDisplay.selectPrinterProfile')}
          </Text>
          <View style={styles.listSection}>
            <ScrollView style={styles.profiles}>
              {printerProfiles?.map((printerProfile, i) => (
                <SettingsItem
                  key={printerProfile.id}
                  type="select"
                  testID={`select-profile-${i}`}
                  title={printerProfile.name}
                  checked={printerProfileIds.includes(printerProfile.id)}
                  onChange={() => selectPrinterProfile(printerProfile.id)}
                />
              ))}
            </ScrollView>
          </View>
          <View style={styles.btnSection}>
            <SettingsAction
              type="positive"
              testID="btn-save"
              label={translate('kitchenDisplay.saveAndContinue')}
              onPress={onPressSave}
              loading={loading}
            />
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default SettingModal;
