import { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { useScale } from '../../hooks/useScale';
import { useColors } from '../../hooks/useColors';
import theme from '../../common/theme';

const SettingsItemStyles = () => {
  const { scale } = useScale();
  const { colors } = useColors();

  return useMemo(
    () =>
      StyleSheet.create({
        container: {
          height: scale(50),
          marginBottom: scale(4),
          flexDirection: 'row',
          alignItems: 'center',
          paddingHorizontal: scale(14),
          borderRadius: theme.radius.m,
          backgroundColor: colors.elevation1,
        },
        sortOptionsContainer: {
          height: scale(50),
          marginBottom: scale(4),
          flexDirection: 'row',
          alignItems: 'center',
          paddingHorizontal: scale(14),
          borderRadius: theme.radius.m,
          backgroundColor: colors.elevation1,
        },
        sortOptionsContainerSelected: {
          height: scale(50),
          marginBottom: scale(4),
          flexDirection: 'row',
          alignItems: 'center',
          paddingHorizontal: scale(14),
          borderRadius: theme.radius.m,
          backgroundColor: colors.elevation2,
        },
        title: {
          flex: 1,
          fontSize: scale(14),
          lineHeight: scale(22),
          color: colors.text,
        },
        sortOptionTitle: {
          flex: 1,
          fontSize: scale(14),
          lineHeight: scale(22),
          color: colors.text,
        },
        subtitle: {
          fontSize: scale(14),
          lineHeight: scale(22),
          color: colors.textLight,
        },
        action: {
          justifyContent: 'center',
        },
        label: {
          flex: 1,
          fontSize: scale(14),
          lineHeight: scale(22),
          textAlign: 'center',
          fontFamily: theme.fonts.medium,
        },
        accessory: {
          flexDirection: 'row',
          alignItems: 'center',
        },
      }),
    [colors, scale],
  );
};

export default SettingsItemStyles;
