import { gql } from '@apollo/client';

export const LOGIN_KDS = gql`
  mutation kdsLoginByDeviceCode($input: KDSDeviceCodeLoginInput!) {
    kdsLoginByDeviceCode(input: $input) {
      appToken
      organization
      venue
      store {
        id
        name
      }
      kitchenDisplay {
        id
        name
      }
    }
  }
`;

export const LOGOUT_KDS = gql`
  mutation kdsLogout($input: KDSLogoutInput!) {
    kdsLogout(input: $input)
  }
`;

export const REFRESH_KDS_TOKEN = gql`
  mutation kdsRefreshToken($input: KDSRefreshTokenInput!) {
    kdsRefreshToken(input: $input)
  }
`;
