import { format } from 'date-fns';
import { Docket, IntegrationApps, OrderTypeCode } from '@oolio-group/domain';
import { table, TableUserConfig } from 'table';

export const isOnlineOrder = (docket: Docket) => {
  // only apply for few integration app: #5678
  return [
    IntegrationApps.OOLIO_STORE,
    IntegrationApps.DELIVERECT,
    IntegrationApps.DOSHII,
    IntegrationApps.OOM,
  ].includes(docket?.integrationInfo?.app as IntegrationApps);
};

export const getOnlineOrderType = (
  docket: Docket,
  tableConfig: TableUserConfig,
  showCustomerName: boolean,
): string => {
  const orderTypeName = docket.orderType?.name?.toLocaleUpperCase();
  let orderReceiver = '';
  if (orderTypeName == 'DINE IN') {
    orderReceiver = `Table ${docket?.table?.name}`;
  } else if (showCustomerName) {
    orderReceiver = docket.customer?.name;
  } else {
    orderReceiver =
      docket.integrationInfo?.channelOrderDisplayId || docket.orderNumber;
  }
  return table([[orderTypeName, orderReceiver]], tableConfig);
};

export const getPickupTime = (docket: Docket): string => {
  const pickUpTime = docket?.dueAt || docket.requiredAt || docket.createdAt;
  return pickUpTime ? format(pickUpTime, 'dd-MM-yyyy hh:mm aa') : '';
};
export const getOnlineOrderDetail = (
  docket: Docket,
  tableConfig: TableUserConfig,
): string => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const tableData: any[] = [];
  // Order Number
  tableData.push(['Order No:', docket.orderNumber]);

  //  Due At
  if (
    [
      OrderTypeCode.PICK_UP.toString(),
      OrderTypeCode.DELIVERY.toString(),
    ].includes(docket.orderType.code || '')
  ) {
    tableData.push(['Due At:', getPickupTime(docket)]);
  }

  return table(tableData, tableConfig);
};
