import {
  Docket,
  DocketItemStatus,
  DocketPayloadInput,
  DocketStatus,
  OrderType,
  SalesChannel,
} from '@oolio-group/domain';
import { SortingPreference, UNASSIGNED_COURSE } from '../../common/constants';
import { cloneDeep } from 'lodash';

export interface CourseDocketItemMap {
  [courseId: string]: DocketPayloadInput[];
}

/**
 *
 * @param docket
 * @returns It will filter and return items without courses first and then subsequent items with course
 */
const getGroupByDocketItemsByCourseId = (docket: Docket) => {
  const docketCopy = cloneDeep(docket);
  let docketItems = docketCopy.docketItems || [];
  docketItems = [
    ...docketItems.filter(i => !i.course?.id),
    ...docketItems.filter(i => i.course?.id),
  ];

  const groupItemsByCourse: CourseDocketItemMap = docketItems.reduce(
    (map, item) => {
      const courseId = item.course?.id || UNASSIGNED_COURSE;
      if (!map[courseId]) map[courseId] = [];
      map[courseId].push(item);
      return map;
    },
    {} as CourseDocketItemMap,
  );
  docketCopy.docketItems = Object.values(groupItemsByCourse).flat();

  return docketCopy;
};

export function getItemCounts(
  filteredDockets: Docket[],
): Record<string, number> {
  const counts: Record<string, number> = {};
  filteredDockets.forEach(docket => {
    if (docket.orderType && docket.itemStatus !== DocketItemStatus.VOIDED) {
      counts[`orderType#${docket.orderType.id}`] =
        (counts[`orderType#${docket.orderType.id}`] || 0) + 1;
    }
    if (docket.salesChannel && docket.itemStatus !== DocketItemStatus.VOIDED) {
      counts[`salesChannel#${docket.salesChannel.id}`] =
        (counts[`salesChannel#${docket.salesChannel.id}`] || 0) + 1;
    }
  });

  return counts;
}

function sortDocketsBasedOnTableName(filteredDockets: Docket[]): Docket[] {
  return filteredDockets.sort((a, b) => {
    const sectionComparison = a.section.name.localeCompare(b.section.name);

    if (sectionComparison === 0) {
      // Extract numerical part of table name and compare as numbers
      const aTableNumber = parseInt(a.table.name.match(/\d+/)?.[0] || '0');
      const bTableNumber = parseInt(b.table.name.match(/\d+/)?.[0] || '0');

      if (aTableNumber === bTableNumber) {
        // If numbers are the same, compare the entire table name as strings
        return a.table.name.localeCompare(b.table.name);
      }

      return aTableNumber - bTableNumber;
    }

    return sectionComparison;
  });
}

export function sortDockets(
  sortingPref: string | undefined,
  filteredDockets: Docket[],
  selectedTab: string,
  orderTypes: OrderType[],
  salesChannels: SalesChannel[],
) {
  if (!filteredDockets) {
    return [];
  }
  const defaultSortingPref = SortingPreference.OLDEST_FIRST;

  let sortedDockets = filteredDockets;
  switch (selectedTab) {
    case DocketStatus.CREATED:
      if (
        (sortingPref || defaultSortingPref) === SortingPreference.NEWEST_FIRST
      ) {
        // sorting based on created time
        sortedDockets = filteredDockets.sort(
          (a, b) => b.createdAt - a.createdAt,
        );
      }
      if (
        (sortingPref || defaultSortingPref) === SortingPreference.OLDEST_FIRST
      ) {
        // sorting based on created time
        sortedDockets = filteredDockets.sort(
          (a, b) => a.createdAt - b.createdAt,
        );
      }
      if (
        (sortingPref || defaultSortingPref) === SortingPreference.TABLE_NAME
      ) {
        // sorting based on section name & table name
        sortedDockets = [
          ...sortDocketsBasedOnTableName(
            filteredDockets.filter(item => item.table && item.table.name),
          ),
          ...filteredDockets.filter(item => !item.table || !item.table.name),
        ];
      }
      if (orderTypes.find(type => type.id === sortingPref)) {
        // sorting based on order type
        sortedDockets = filteredDockets.sort((a, b) => {
          if (orderTypes.find(channel => channel.id === sortingPref)) {
            // sorting based on sales channel and time simultaneously
            if (
              a.orderType.id === sortingPref &&
              b.orderType.id !== sortingPref
            ) {
              return -1;
            } else if (
              a.orderType.id !== sortingPref &&
              b.orderType.id === sortingPref
            ) {
              return 1;
            } else {
              return a.createdAt - b.createdAt;
            }
          }
          return a.createdAt - b.createdAt;
        });
      }
      if (salesChannels.find(channel => channel.id === sortingPref)) {
        // sorting based on sales channel
        sortedDockets = filteredDockets.sort((a, b) => {
          if (salesChannels.find(channel => channel.id === sortingPref)) {
            // sorting based on sales channel and time simultaneously
            if (
              a.salesChannel.id === sortingPref &&
              b.salesChannel.id !== sortingPref
            ) {
              return -1;
            } else if (
              a.salesChannel.id !== sortingPref &&
              b.salesChannel.id === sortingPref
            ) {
              return 1;
            } else {
              return a.createdAt - b.createdAt;
            }
          }
          return a.createdAt - b.createdAt;
        });
      }
      break;
    case DocketStatus.COMPLETED:
      sortedDockets = filteredDockets.sort(
        (a, b) => b.bumpedTime - a.bumpedTime,
      );
      break;
  }
  sortedDockets = sortedDockets.map(getGroupByDocketItemsByCourseId);

  return sortedDockets;
}
