import { useSubscription } from '@apollo/client/react/hooks';
import { KitchenDisplayActions } from '@oolio-group/domain';
import { useCallback } from 'react';
import { analyticsService } from '../analytics/AnalyticsService';
import { KdsDeviceEvents } from '../graphql/subscription';
import { useSession } from './useSession';
import { useSettings } from './useSettings';

export const useDeviceEvent = () => {
  const { session } = useSession();
  const { logout } = useSettings();

  const onLogout = useCallback(() => {
    analyticsService.capture('Logged Out');
    if (session?.kitchenDisplay?.id) logout(session?.kitchenDisplay?.id);
  }, [session, logout]);

  useSubscription<{
    kdsDeviceEvents: {
      kitchenDisplayId: string;
      storeId: string;
      organizationId: string;
      action: string;
      uuid: string;
    }[];
  }>(KdsDeviceEvents, {
    variables: {
      storeId: session.currentStore?.id,
      kitchenDisplayId: session.kitchenDisplay?.id,
    },
    onSubscriptionData: ({ subscriptionData }) => {
      if (subscriptionData.data?.kdsDeviceEvents?.[0]) {
        const event = subscriptionData.data?.kdsDeviceEvents?.[0];
        switch (event.action) {
          case KitchenDisplayActions.LOGOUT_ACTION:
            if (event.uuid !== session.kitchenDisplay?.uuid) {
              onLogout();
            }
            break;

          default:
            break;
        }
      }
    },
  });
};
