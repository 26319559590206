import React, { useContext, useEffect, useState } from 'react';
import * as sessionPreferences from '../state/preferences';

export type SessionContextValue = {
  session: sessionPreferences.Session;
  setSession: (session: Partial<sessionPreferences.Session>) => void;
  clearSession: () => void;
};

export const SessionContext = React.createContext({} as SessionContextValue);

export type SessionProviderProps = {
  children: React.ReactNode;
};

export const SessionProvider = ({ children }: SessionProviderProps) => {
  const [session, setSessionInState] = useState<sessionPreferences.Session>({
    authorized: false,
  });

  const clearSession = () => {
    setSessionInState({ authorized: false });
    sessionPreferences.setSession({ authorized: false });
  };

  const setSession = (session: Partial<sessionPreferences.Session>) => {
    setSessionInState(oldSession => {
      const newSession = {
        ...oldSession,
        ...session,
      };
      sessionPreferences.setSession(newSession);
      return newSession;
    });
  };

  const getSession = async () => {
    return (await sessionPreferences.getSession()) as sessionPreferences.Session;
  };

  useEffect(() => {
    (async () => {
      const session = await getSession();
      setSessionInState(session);
    })();
  }, []);

  const value = {
    session,
    setSession,
    clearSession,
  };

  return (
    <SessionContext.Provider value={value}>{children}</SessionContext.Provider>
  );
};

export const useSession = (): SessionContextValue =>
  useContext<SessionContextValue>(SessionContext);
