import { sendNotification } from '../hooks/useNotification';
import { WorkerActionResult, WorkerInput } from './utils';
import { Platform } from 'react-native';
class BackgroundWorker {
  counter = 0;
  worker: Worker;

  constructor() {
    try {
      switch (Platform.OS) {
        case 'android':
        case 'ios':
          {
            const PrintWorker = require('./worker').default;
            this.worker = new PrintWorker() as unknown as Worker;
          }
          break;
        default:
          {
            this.worker = new Worker('./worker.web.js', { type: 'module' });
          }
          break;
      }
    } catch {
      this.worker = undefined as unknown as Worker;
      return;
    }
    this.worker.onmessage = this.receive.bind(this);
  }

  send({ action, data, priority }: WorkerInput) {
    const requestId = `${Date.now()}${++this.counter}`;
    const workerInput: WorkerInput = { requestId, action, data, priority };
    return this.worker.postMessage(JSON.stringify(workerInput));
  }

  async receive(event: WorkerActionResult | { data: WorkerActionResult }) {
    const data = (Platform.OS === 'web' ? event.data : event) as string;
    try {
      const result = (data && JSON.parse(data)) as WorkerActionResult;
      if (result.status === 'ERROR') {
        sendNotification({
          error: true,
          message: result.data?.errorMessage || result.message || 'Print Error',
        });
      }
    } catch {}
  }
}

export default BackgroundWorker;
