import { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { useColors } from '../../hooks/useColors';
import { useScale } from '../../hooks/useScale';
import theme from '../../common/theme';

const SidebarStyles = () => {
  const { colors } = useColors();
  const { scale } = useScale();

  return useMemo(
    () =>
      StyleSheet.create({
        sidebar: {
          height: theme.window.vh,
          width: scale(300),
          backgroundColor: colors.popupBg,
          position: 'absolute',
          left: 0,
          zIndex: theme.elevations.sidebar,
          shadowOpacity: 0.1,
          shadowOffset: { width: 0, height: 0 },
          shadowRadius: 6,
          elevation: 6,
          shadowColor: colors.shadow,
        },
        titleContainer: {
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: scale(60),
        },
        titleTextContainer: {
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingHorizontal: scale(6),
        },
        titleText: {
          color: colors.text,
          fontSize: scale(14),
          paddingHorizontal: scale(8),
          fontFamily: theme.fonts.medium,
        },
        scrollContainer: {
          flex: 1,
        },
        content: {
          alignItems: 'center',
          paddingHorizontal: scale(10),
          paddingBottom: scale(10),
        },
        actions: {
          height: scale(60),
          width: '100%',
        },
      }),
    [colors, scale],
  );
};

export default SidebarStyles;
