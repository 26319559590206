/* eslint-disable @typescript-eslint/no-explicit-any */
import { App } from '@oolio-group/domain';
import { useDeviceCodeLogin } from '@oolio-group/hooks';
import { useNavigation } from '@react-navigation/native';
import jwt_decode from 'jwt-decode';
import React, { useEffect, useRef } from 'react';
import { ActivityIndicator, Text, View } from 'react-native';
import QrCode from 'react-native-qrcode-svg';
import {
  POSTHOG_EVENT_ACTIONS,
  POSTHOG_EVENT_DATA,
  analyticsService,
} from '../../analytics/AnalyticsService';
import {
  ORDER_TYPES_STORAGE_KEY,
  ORG_TIMINGS_STORAGE_KEY,
  SALES_CHANNEL_STORAGE_KEY,
} from '../../common/constants';
import { Nav } from '../../common/types';
import ButtonIcon from '../../component/Buttons/ButtonIcon';
import Gradient from '../../component/Gradient/Gradient';
import config from '../../config';
import { useSession } from '../../hooks/useSession';
import { tokenState } from '../../state/apolloVars';
import { Session } from '../../state/preferences';
import { setItem } from '../../storage/interface';
import LoginStyles from './LoginScreen.styles';

const deviceCodeConfig = {
  clientId: config.auth0.clientId,
  audience: config.auth0.additionalParameters.audience,
  getTokenUrl: `${config.auth0.issuer}/oauth/token`,
  getDeviceCodeUrl: `${config.auth0.issuer}/oauth/device/code`,
};

const LoginScreen: React.FC = () => {
  const styles = LoginStyles();
  const { setSession } = useSession();
  const navigation = useNavigation<Nav>();
  const {
    getDeviceCode,
    verificationUri,
    tokenPayload,
    userCode,
    expiresInRef: qrCodeTimeoutRef,
  } = useDeviceCodeLogin(deviceCodeConfig);

  const refreshQrCodeIntervalRef = useRef<any>();
  useEffect(() => {
    getDeviceCode();
  }, [getDeviceCode]);

  useEffect(() => {
    // refresh the QR code when it expires
    if (userCode && qrCodeTimeoutRef.current)
      refreshQrCodeIntervalRef.current = setTimeout(() => {
        getDeviceCode();
      }, qrCodeTimeoutRef.current);
    return () => {
      clearTimeout(refreshQrCodeIntervalRef.current);
    };
  }, [getDeviceCode, qrCodeTimeoutRef, userCode]);

  useEffect(() => {
    if (tokenPayload) {
      const idTokenPayload = jwt_decode<{
        app_metadata: any;
      }>(tokenPayload.idToken as string);

      const accessTokenPayload = jwt_decode<{
        exp: number;
      }>(tokenPayload.accessToken as string);
      const session: Session = {
        authorized: true,
        activeApp: App.KITCHEN_DISPLAY_APP,
        token: tokenPayload.accessToken,
        refreshToken: tokenPayload.refreshToken,
        expiredDate: accessTokenPayload.exp * 1000,
        localeCode: config.DEFAULT_LOCALE,
        currentOrganization: {
          id: idTokenPayload.app_metadata.organization_id,
          name: idTokenPayload.app_metadata?.organization_name,
        },
      };
      setItem(SALES_CHANNEL_STORAGE_KEY, []);
      setItem(ORDER_TYPES_STORAGE_KEY, []);
      setItem(ORG_TIMINGS_STORAGE_KEY, undefined);
      setSession(session);
      tokenState(session);
      analyticsService.capture(POSTHOG_EVENT_ACTIONS.LOGIN, {
        orgId: idTokenPayload?.app_metadata?.organization_id,
        orgName: idTokenPayload?.app_metadata?.organization_name,
      } as POSTHOG_EVENT_DATA);
      navigation.navigate('AssignKds');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenPayload, navigation]);

  return (
    <Gradient>
      <View style={styles.screen}>
        <View style={styles.modalContainer}>
          <Text style={styles.title}>Scan QR to begin pairing your KDS</Text>
          {!!verificationUri ? (
            <>
              <View style={styles.qrContainer}>
                <QrCode value={verificationUri} size={160} />
                <ButtonIcon
                  icon="sync"
                  testID="btn-refresh"
                  type="neutralLight"
                  onPress={getDeviceCode}
                  containerStyle={styles.btnRefresh}
                />
              </View>
              <View style={styles.codeContainer}>
                <Text style={styles.code}>{userCode}</Text>
              </View>
            </>
          ) : (
            <View style={styles.qrContainer}>
              <ActivityIndicator size={20} />
            </View>
          )}
          <Text style={styles.footnote}>
            Verify the code above with the one being displayed. If the codes do
            not match, refresh the code and scan again.
          </Text>
        </View>
      </View>
    </Gradient>
  );
};

export default LoginScreen;
