import { useNavigation } from '@react-navigation/native';
import { usePrinterProfiles } from './usePrinterProfiles';
import { useSession } from './useSession';
import { useCallback, useState } from 'react';
import { useAuth } from './useAuth';
import { clearExistingDockets } from '../storage/docket';
import { DocketStatus } from '@oolio-group/domain';
import { setItem } from '../storage/interface';
import {
  ORDER_TYPES_STORAGE_KEY,
  ORG_TIMINGS_STORAGE_KEY,
  SALES_CHANNEL_STORAGE_KEY,
} from '../common/constants';
import { StackNavigationProp } from '@react-navigation/stack';

export interface UseSettingsProps {
  loading: boolean;
  logout: (id: string) => void;
  audioToggle: () => void;
  updatePrinterProfiles: (
    printerProfilesIds: string[],
    callback?: Function,
  ) => void;
  clearData: () => void;
}
export function useSettings(): UseSettingsProps {
  const { setSession, session, clearSession } = useSession();
  const { assignPrinterProfiles, loading } = usePrinterProfiles();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const navigation = useNavigation<StackNavigationProp<any>>();
  const [toggle, setToggle] = useState(session?.audioToggle || false);
  const { logout: logoutFromServer } = useAuth();
  const clearDocketKeys = async () => {
    await clearExistingDockets(DocketStatus.COMPLETED);
    await clearExistingDockets(DocketStatus.CREATED);
  };

  const assignPrinterProfilesToKds = useCallback(
    async (printerProfilesIds: string[], callback?: Function) => {
      const callbackForAssignPrinterProfiles = async () => {
        callback && callback();
        await clearDocketKeys();
      };
      assignPrinterProfiles(
        printerProfilesIds,
        callbackForAssignPrinterProfiles,
      );
    },
    [assignPrinterProfiles],
  );
  const logout = async (id: string) => {
    await logoutFromServer(id);
    clearSession();
    await clearDocketKeys();
    await setItem(SALES_CHANNEL_STORAGE_KEY, []);
    await setItem(ORDER_TYPES_STORAGE_KEY, []);
    setItem(ORG_TIMINGS_STORAGE_KEY, undefined);
    navigation.reset({ routes: [{ name: 'LoginScreen' }] });
  };

  const audioToggle = useCallback(() => {
    setToggle(toggle => !toggle);
    setSession({ audioToggle: !toggle });
  }, [setSession, toggle]);

  return {
    logout: logout,
    updatePrinterProfiles: assignPrinterProfilesToKds,
    loading,
    clearData: clearDocketKeys,
    audioToggle,
  };
}
