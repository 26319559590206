import React from 'react';
import { Text, TouchableOpacity } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import { RECALL_TEXT } from '../../../common/constants';
import DocketItemStyles from '../DocketItem.styles';

export interface DocketRecallButton {
  onPressRecall: () => void;
}

const DocketRecallButton: React.FC<DocketRecallButton> = ({
  onPressRecall,
}) => {
  const styles = DocketItemStyles();
  const { translate } = useTranslation();

  return (
    <TouchableOpacity
      testID="docket-recall"
      style={styles.statusContainer}
      onPress={onPressRecall}
    >
      <Text
        style={[styles.statusTextUppercase, styles.recallText]}
      >{`↩ ${translate(`kitchenDisplay.${RECALL_TEXT}`)}`}</Text>
    </TouchableOpacity>
  );
};

export default React.memo(DocketRecallButton);
