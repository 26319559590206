import { useLazyQuery } from '@apollo/client/react/hooks';
import { GET_SECTIONS } from '../../graphql/sections';

export const useSections = () => {
  const [getSectionsRequest, { data, loading, error }] = useLazyQuery(
    GET_SECTIONS,
    {
      fetchPolicy: 'no-cache',
    },
  );

  return {
    sections: data?.sections,
    loading,
    error,
    getSectionsRequest,
  };
};
