import { ApolloError } from '@apollo/client';
import { SalesChannel } from '@oolio-group/domain';
import { useEffect, useMemo, useState } from 'react';
import { useLazyQuery } from '@apollo/client/react/hooks';
import { getItem, setItem } from '../storage/interface';
import { GET_SALES_CHANNELS } from '../graphql/salesChannel';
import { SALES_CHANNEL_STORAGE_KEY } from '../common/constants';
import { noopHandler } from '../utils/general';

export interface UseSalesChannelProps {
  loading: boolean;
  error: string | undefined;
  salesChannels: SalesChannel[];
  getSalesChannels: () => void;
}

export function useSalesChannels(): UseSalesChannelProps {
  const [salesChannels, setSalesChannels] = useState<SalesChannel[]>([]);

  const getSalesChannelsFromStorage = async () => {
    const salesChannels =
      ((await getItem(SALES_CHANNEL_STORAGE_KEY)) as SalesChannel[]) || [];
    setSalesChannels(salesChannels);
    return salesChannels;
  };

  const [getSalesChannels, getSalesChannelsResponse] = useLazyQuery(
    GET_SALES_CHANNELS,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: data => {
        if (data?.salesChannels) {
          setSalesChannels(data.salesChannels);
          setItem(SALES_CHANNEL_STORAGE_KEY, data.salesChannels);
        }
      },
      onError: noopHandler,
    },
  );

  useEffect(() => {
    const fetchSalesChannelIfNotCache = async () => {
      const salesChannel = await getSalesChannelsFromStorage();
      if (!salesChannel || !salesChannel.length) {
        getSalesChannels();
      }
    };
    fetchSalesChannelIfNotCache();
  }, [getSalesChannels]);

  const loading: boolean = getSalesChannelsResponse.loading;

  const error: ApolloError | undefined = getSalesChannelsResponse.error;

  return useMemo(
    () => ({
      loading,
      error: error ? (error as ApolloError)?.message : '',
      salesChannels,
      getSalesChannels,
    }),
    [loading, error, salesChannels, getSalesChannels],
  );
}
