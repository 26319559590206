import React, { useMemo } from 'react';
import { View } from 'react-native';
import PaginationStyles from './Pagination.styles';
import ButtonIcon from '../Buttons/ButtonIcon';
import ButtonText from '../Buttons/ButtonText';

export interface PaginationProps {
  data: number[];
  currentPageIndex: number;
  onChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  data,
  currentPageIndex,
  onChange,
}) => {
  const styles = PaginationStyles();

  const pagesToShow = useMemo(() => {
    if (data) {
      const totalPages = data.length;
      if (totalPages <= 5) {
        return data;
      }

      const pageNumbers: number[] = [];
      if (currentPageIndex <= 3) {
        pageNumbers.push(...data.slice(0, 5));
      } else if (currentPageIndex >= totalPages - 2) {
        pageNumbers.push(...data.slice(totalPages - 5, totalPages));
      } else {
        pageNumbers.push(
          currentPageIndex - 2,
          currentPageIndex - 1,
          currentPageIndex,
          currentPageIndex + 1,
          currentPageIndex + 2,
        );
      }

      return pageNumbers;
    }
  }, [currentPageIndex, data]);

  return (
    <View testID="view-pagination" style={styles.container}>
      <ButtonIcon
        testID="btn-start"
        icon="left-arrow-to-left"
        disabled={currentPageIndex === 0}
        onPress={() => onChange(0)}
      />
      {data?.length ? (
        <View style={styles.pages}>
          <ButtonIcon
            testID="btn-prev"
            icon="arrow-left"
            disabled={currentPageIndex === 0}
            onPress={() => onChange(currentPageIndex - 1)}
          />
          {pagesToShow
            ? pagesToShow.map((page, i) => (
                <ButtonText
                  key={i}
                  testID="btn-page"
                  label={page.toString()}
                  onPress={() => {
                    onChange(page - 1);
                  }}
                  type={currentPageIndex === page - 1 ? 'active' : undefined}
                />
              ))
            : null}
          <ButtonIcon
            testID="btn-next"
            icon="arrow-right"
            disabled={currentPageIndex === data.length - 1}
            onPress={() => onChange(currentPageIndex + 1)}
          />
        </View>
      ) : null}
      <ButtonIcon
        testID="btn-end"
        icon="arrow-to-right"
        disabled={currentPageIndex === data.length - 1}
        onPress={() => onChange(data.length - 1)}
      />
    </View>
  );
};

export default Pagination;
