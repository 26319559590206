import { gql } from '@apollo/client';

export const GET_SETTINGS_QUERY = gql`
  query getSettings {
    orderTypes {
      id
      name
      code
      isActive
    }
    dateTime {
      startTime
      startDay
      startMonth
    }
    salesChannels {
      id
      name
      isActive
    }
  }
`;
