import React, { useContext, useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { IOS_SCALE, ScaleFactor } from '../common/constants';
import { Scale } from '../common/types';
import * as preferences from '../state/preferences';

export type ScaleContextValue = {
  currentScale: Scale;
  setScale: (scale: Scale) => void;
  scale: (number: number) => number;
};

export const ScaleContext = React.createContext({} as ScaleContextValue);

export type ScaleProviderProps = {
  children: React.ReactNode;
};

export const ScaleProvider = ({ children }: ScaleProviderProps) => {
  const [currentScale, setCurrentScale] = useState<Scale>(Scale.MEDIUM);

  // Function to Change Scale
  const setScale = async (scale: Scale) => {
    setCurrentScale(scale);
    await preferences.setScale(scale);
  };

  // Function to Get Scale
  const getScale = async () => {
    return (await preferences.getScale()) as Scale;
  };

  // Function to Scale
  const scale = (n: number) => {
    const platformFactor = Platform.OS === 'ios' ? IOS_SCALE : 1;

    switch (currentScale) {
      case Scale.MEDIUM:
        return n * platformFactor * ScaleFactor.MEDIUM;
      case Scale.LARGE:
        return n * platformFactor * ScaleFactor.LARGE;
      default:
        return n * platformFactor * ScaleFactor.SMALL;
    }
  };

  useEffect(() => {
    (async () => {
      const scale = await getScale();
      setCurrentScale(scale);
    })();
  }, []);

  const value = {
    scale,
    setScale,
    currentScale,
  };
  return (
    <ScaleContext.Provider value={value}>{children}</ScaleContext.Provider>
  );
};

export const useScale = (): ScaleContextValue =>
  useContext<ScaleContextValue>(ScaleContext);
