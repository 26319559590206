import React, { useEffect } from 'react';
import { useAudioNotification } from '../../hooks/useAudioNotification/useAudioNotification';
import { useReactiveVar } from '@apollo/client/react/hooks';
import { useSession } from '../../hooks/useSession';
import { docketsUpdated } from '../../state/apolloVars';
import { DocketSource, DocketUpdate } from '../types';
export interface audioTrigger {
  alert: boolean;
}
const DocketAudioNotification: React.FC = () => {
  const docketsUpdatedVar = useReactiveVar<DocketUpdate>(docketsUpdated);
  const { session } = useSession();
  const audioNotification = useAudioNotification();
  useEffect(() => {
    const alert = session.audioToggle;
    if (alert) {
      if (
        docketsUpdatedVar.source == DocketSource.SUBSCRIPTION ||
        docketsUpdatedVar.triggerSound
      ) {
        audioNotification.playWithDelay(2000);
      }
    }
  }, [docketsUpdatedVar, audioNotification, session.audioToggle]);

  return <></>;
};

export default DocketAudioNotification;
