import {
  PrinterTemplate,
  RemoveOrderItemEvent,
  Course,
  PrinterSeries,
  LOCALE,
  DEFAULT_TABLE_ABBREVIATION,
  SML_KITCHEN_DOCKET,
  LRG_KITCHEN_DOCKET,
  AllergensKey,
  ProductAllergens,
  Docket,
  DocketItem,
  IntegrationApps,
  DocketItemStatus,
} from '@oolio-group/domain';
import { EscPos } from '@tillpos/xml-escpos-helper';
import { table, getBorderCharacters, TableUserConfig } from 'table';
import { Session } from '../../state/preferences';
import { dashDivider, divider } from './printDivider';
import { sortBy, get } from 'lodash';
import { format } from 'date-fns';
import {
  getOnlineOrderDetail,
  getOnlineOrderType,
  isOnlineOrder,
} from './generateOnlineOrderDetail';
import { getOrderChannel } from './salesChannel';
import {
  kitchenDocketLabelTemplate,
  kitchenDocketTemplate,
  lrgKitchenDocketTemplate,
  smlkitchenDocketTemplate,
} from './xmlTemplates';
import { isDeselectDefaultOption } from '@oolio-group/client-utils';
const nameToTemplate: { [key: string]: string } = {
  [SML_KITCHEN_DOCKET]: smlkitchenDocketTemplate,
  [LRG_KITCHEN_DOCKET]: lrgKitchenDocketTemplate,
};
export interface CoursePrintItems {
  courseName: string;
  priority: number;
  printItems: DocketItem[];
}

export type PrintableProductRow = [string | undefined, string | undefined];

export interface RemoveOrderItemDocketEvent extends RemoveOrderItemEvent {
  quantity?: number;
}
interface OrderItemsPrintDetails {
  groupedItems: GroupedPrintItems[];
  header?: string;
}
interface PrintableLineItems {
  product: string;
  note: string;
  modifiers: string;
}
interface GroupedPrintItems {
  items: PrintableLineItems[];
  seat?: string;
  allergens?: string;
}
type PartialPrintableKitchenOrder = (args: {
  docket: Docket;
  session: Session;
  template: PrinterTemplate;
  printItems: DocketItem[];
  hasVoidOrderItem?: boolean;
  translatedNowCourse: string;
  profileName?: string;
  locale?: LOCALE;
  printerSeries?: PrinterSeries;
  enableKitchenBuzzer?: boolean;
  singleItemPrinting?: boolean;
  currentItemNumber?: number;
  totalItems?: number;
}) => Buffer | undefined;

/**
 * Order details section has two columns and `n` row(s)
 */
//for text width 1
export const twoColumnLargeTextConfig: TableUserConfig = {
  columns: {
    0: { alignment: 'left', width: 9 },
    1: { alignment: 'right', width: 10, wrapWord: true },
  },
  border: getBorderCharacters('void'),
  columnDefault: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  drawHorizontalLine: () => {
    return false;
  },
};

//for text width 0
export const twoColumnConfig: TableUserConfig = {
  columns: {
    0: { alignment: 'left', width: 18 },
    1: { alignment: 'right', width: 20 },
  },
  border: getBorderCharacters('void'),
  columnDefault: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  drawHorizontalLine: () => {
    return false;
  },
};

const tableOrderItemConfig: TableUserConfig = {
  columns: {
    0: { width: 4, alignment: 'right' },
    1: {
      width: 17,
      alignment: 'left',
      wrapWord: true,
    },
  },
  border: getBorderCharacters('void'),
  drawHorizontalLine: () => {
    return false;
  },
};

const tableOrderItemModifierConfig: (
  template?: PrinterTemplate,
) => TableUserConfig = template =>
  template?.name === LRG_KITCHEN_DOCKET
    ? {
        columns: {
          0: { width: 2, alignment: 'left' },
          1: {
            width: 12,
            alignment: 'left',
            wrapWord: true,
          },
        },
        border: getBorderCharacters('void'),
        columnDefault: {
          paddingLeft: 0,
          paddingRight: 1,
        },
        drawHorizontalLine: () => {
          return false;
        },
      }
    : {
        columns: {
          0: { width: 4, alignment: 'left' },
          1: {
            width: 25,
            alignment: 'left',
            wrapWord: true,
          },
        },
        border: getBorderCharacters('void'),
        columnDefault: {
          paddingLeft: 0,
          paddingRight: 1,
        },
        drawHorizontalLine: () => {
          return false;
        },
      };

const tableOrderItemNoteConfig: (
  template?: PrinterTemplate,
) => TableUserConfig = template =>
  template?.name === LRG_KITCHEN_DOCKET
    ? {
        columns: {
          0: { width: 14, wrapWord: true },
        },
        border: getBorderCharacters('void'),
        drawHorizontalLine: () => {
          return false;
        },
      }
    : {
        columns: {
          0: { width: 28, wrapWord: true },
        },
        border: getBorderCharacters('void'),
        drawHorizontalLine: () => {
          return false;
        },
      };

export const getPrintableKitchenOrder: PartialPrintableKitchenOrder = ({
  docket,
  printItems,
  session,
  hasVoidOrderItem,
  template,
  translatedNowCourse,
  profileName,
  locale,
  printerSeries,
  singleItemPrinting,
  currentItemNumber,
  totalItems,
}) => {
  let printableItems = [] as OrderItemsPrintDetails[];
  const shouldPrintProfile =
    session.device?.deviceProfile?.showPrinterProfileInDocket || false;
  const groupBySeat =
    (session.device?.deviceProfile?.enableSeatManagement &&
      docket.orderType.name === 'Dine In') ||
    false;
  if (printCourse(docket)) {
    const itemsWithCourse = printItems.reduce((courses, item) => {
      const course =
        item.course || ({ name: translatedNowCourse, priority: -1 } as Course);
      const courseIndex = courses.findIndex(x => x.courseName === course.name);
      if (courseIndex < 0) {
        courses.push({
          courseName: course.name || '',
          priority: 1,
          printItems: [item],
        });
      } else {
        const existCourse = courses[courseIndex];
        courses[courseIndex] = {
          ...existCourse,
          printItems: [...existCourse.printItems, item],
        };
      }

      return courses;
    }, [] as CoursePrintItems[]);

    const sortedCourses = sortBy(itemsWithCourse, course => course.priority);
    printableItems = getOrderItemsPrintStringByCourse(
      sortedCourses,
      locale,
      groupBySeat,
      template,
    );
  } else {
    printableItems = [
      {
        groupedItems: getOrderItemsPrintString(
          printItems,
          locale,
          groupBySeat,
          template,
        ),
      },
    ];
  }
  if (singleItemPrinting) {
    return getPrintableBufferForSingleItemLabelPrinting({
      ...(shouldPrintProfile ? { profileName: profileName || '' } : {}),
      orderItems: printableItems,
      originalDocket: docket,
      printerSeries: printerSeries || PrinterSeries.TM_M30II,
      currentItemNumber,
      totalItems,
    });
  }
  return getPrintableBuffer({
    ...(shouldPrintProfile ? { profileName: profileName || '' } : {}),
    orderItems: printableItems,
    originalDocket: docket,
    template,
    title: hasVoidOrderItem
      ? 'CANCELLATION'
      : docket.isEdited
      ? 'EDIT ORDER'
      : 'NEW ORDER',
    printerSeries: printerSeries || PrinterSeries.TM_M30II,
    session,
  });
};

/**
 * ========================================================================================
 * Utilities for Partial orders (partial order items)
 * ========================================================================================
 *
 */

/**
 * Get product name (for all types of events)
 *
 * return newly added ot modified variants or notes
 * @param item
 * @param events
 */
// FIXME: once modifier events have been added here
export const getProductDetails = (item: DocketItem, locale?: LOCALE) => {
  locale = locale ? locale : LOCALE.ENGLISH_US;
  //const variant = item.variant;
  const product = item.product;
  return product?.name;
};

export const appendModifiers = (
  acc: PrintableProductRow[],
  item: DocketItem,
  locale?: LOCALE,
) => {
  if (item.modifiers && item.modifiers?.length > 0) {
    item.modifiers.forEach(modifier => {
      const printQuantity = modifier.quantity || 1;
      const name = modifier?.name;
      if (printQuantity == 1) {
        const isDeselectDefault = isDeselectDefaultOption(name);
        const sign = isDeselectDefault ? '' : '+';
        acc.push([sign, `${name || ''}`]);
      } else {
        acc.push([`${printQuantity}`, `${name || ''}`]);
      }
    });
  }
};

/**
 * Returns partial order (items) printable array
 *
 * @param partialItems
 * @param events
 */
export const getOrderItemsPrintString = (
  printItems: DocketItem[],
  locale?: LOCALE,
  seatManagement?: boolean,
  template?: PrinterTemplate,
): GroupedPrintItems[] => {
  const groupedItems: { [key: string]: DocketItem[] } = seatManagement
    ? printItems.reduce<Record<string, DocketItem[]>>((acc, item) => {
        const seatNumber = item
          ? 'SEAT ' + item.docketId
          : DEFAULT_TABLE_ABBREVIATION;
        acc[seatNumber] = [...(acc[seatNumber] || []), item];
        return acc;
      }, {})
    : { [DEFAULT_TABLE_ABBREVIATION]: printItems };
  const result: GroupedPrintItems[] = [];

  Object.keys(groupedItems)
    .sort((a, b) => {
      if (a === b) {
        return 0;
      }
      if (
        a === DEFAULT_TABLE_ABBREVIATION ||
        (b !== DEFAULT_TABLE_ABBREVIATION && a < b)
      ) {
        return -1;
      }
      return 1;
    })
    .forEach(cur => {
      const items = groupedItems[cur];
      const groupResult: PrintableLineItems[] = [];
      items.forEach(x => {
        const product = getProductDetails(x, locale);
        const modifierRows: PrintableProductRow[] = [];
        const isVoidedItem = x.itemStatus === DocketItemStatus.VOIDED;
        const quantity = isVoidedItem ? `-${x.quantity}` : `${x.quantity}`;
        const productRow: PrintableProductRow = [`${quantity}`, product];
        appendModifiers(modifierRows, x, locale);
        const printableLineItem = {
          product: table([productRow], tableOrderItemConfig),
          note: x?.notes
            ? table([[`** ${x?.notes} **`]], tableOrderItemNoteConfig(template))
            : '',
          //currently double array is needed for reason and notes
          modifiers:
            modifierRows.length > 0
              ? '\n' +
                table(modifierRows, tableOrderItemModifierConfig(template))
              : '',
        };
        groupResult.push(printableLineItem);
      });
      result.push({
        ...(seatManagement && {
          seat: cur,
        }),
        items: groupResult,
      });
    });
  return result;
};

/**
 * Returns partial order (items) printable array with Course Layout
 *
 * @param partialItems
 */
export const getOrderItemsPrintStringByCourse = (
  itemsWithCourse: CoursePrintItems[],
  locale?: LOCALE,
  seatManagement?: boolean,
  template?: PrinterTemplate,
): OrderItemsPrintDetails[] => {
  const printableOrder = itemsWithCourse.reduce(
    (acc: OrderItemsPrintDetails[], course) => {
      const { courseName, printItems } = course;
      acc.push({
        groupedItems: getOrderItemsPrintString(
          printItems,
          locale,
          seatManagement,
          template,
        ),
        header: courseName.toLocaleUpperCase() + '\n',
      });
      return acc;
    },
    [],
  );

  return printableOrder;
};

function getShortVersion(orderNumber: string): string {
  if (orderNumber && typeof orderNumber === 'string') {
    return orderNumber.split('-').slice(0, -1).join('-');
  }

  return '';
}

export const printCourse = (docket: Docket) => {
  const courses = docket.docketItems.filter(e => e.course != null);
  return courses.length > 0;
};

export const getOrderType = (
  docket: Docket,
  showCustomerName: boolean,
  config?: TableUserConfig,
): string => {
  if (isOnlineOrder(docket))
    return getOnlineOrderType(
      docket,
      config ? config : twoColumnLargeTextConfig,
      showCustomerName,
    );

  const orderTypeName = docket.orderType?.name.toLocaleUpperCase() || 'DINE IN';

  const orderNumber = getShortVersion(docket.orderNumber);
  const orderIdentifier =
    orderTypeName == 'DINE IN' && docket?.table?.name
      ? `Table ${docket?.table?.name}`
      : orderNumber;
  return table(
    [[orderTypeName, orderIdentifier]],
    config ? config : twoColumnLargeTextConfig,
  );
};

export const getTableDetail = (docket: Docket): string => {
  if (docket.isOnline) return getOnlineOrderDetail(docket, twoColumnConfig);
  const tableData = ['createdBy.name', 'createdByDevice.name'];

  let rowInfo: string[] = [];
  const result = tableData.reduce((acc, infoPath) => {
    const orderDetail = get(docket, infoPath);
    if (!orderDetail) return acc;
    rowInfo.push(orderDetail);
    if (rowInfo.length == 2) {
      acc.push([...rowInfo] as [string, string]);
      rowInfo = [];
    }
    return acc;
  }, [] as [string, string][]);
  if (!result.length) return '';

  return table(result, twoColumnConfig);
};
const getPrinterColumns = (
  printerSeries: PrinterSeries,
  singleItemLabelPrinting: boolean,
): number => {
  if (singleItemLabelPrinting) {
    return printerSeries !== PrinterSeries.TM_U220 ? 42 : 36;
  }
  return printerSeries !== PrinterSeries.TM_U220 ? 48 : 42;
};

const getDocketTimeStamp = (originalDocket: Docket): number | undefined => {
  return originalDocket.salesChannel?.name?.toLowerCase() == 'online' ||
    originalDocket.integrationInfo?.app === IntegrationApps.OOLIO_STORE
    ? originalDocket.placedAt || originalDocket.updatedAt
    : originalDocket.updatedAt;
};

const getTokenNumber = (originalDocket: Docket): string | undefined => {
  return Number.isInteger(originalDocket.tokenNumber)
    ? originalDocket.tokenNumber?.toString()
    : '';
};

export const getPrintableBufferForSingleItemLabelPrinting = (args: {
  originalDocket: Docket;
  orderItems: OrderItemsPrintDetails[];
  printerSeries: PrinterSeries;
  currentItemNumber?: number;
  totalItems?: number;
}): Buffer | undefined => {
  const {
    originalDocket,
    orderItems,
    printerSeries,
    currentItemNumber,
    totalItems,
  } = args;

  const timestamp = getDocketTimeStamp(originalDocket);

  const tokenNumber = getTokenNumber(originalDocket);

  const columns = getPrinterColumns(printerSeries, true);
  const time = format(timestamp || new Date(), 'dd MMM - HH:mm').toUpperCase();

  const title = table(
    [[time, `${currentItemNumber}/${totalItems}`]],
    twoColumnConfig,
  );

  const footer = table(
    [
      [
        originalDocket?.salesChannel?.name,
        originalDocket?.createdBy?.name ||
          originalDocket?.createdByDevice?.name,
      ],
    ],
    twoColumnConfig,
  );
  const data = {
    orderName: originalDocket.customer.name || tokenNumber,
    orderItems,
    divider: divider(columns) + '\n',
    title: title,
    orderType: getOrderType(originalDocket, false, twoColumnConfig),
    footer: footer,
    printMode: printerSeries !== PrinterSeries.TM_U220 ? 'REST' : 'U220',
    currentItemNumber: currentItemNumber,
    totalItems: totalItems,
  };
  return EscPos.getBufferFromTemplate(
    kitchenDocketLabelTemplate,
    data,
  ) as unknown as Buffer;
};

export const getCustomerInfo = (
  docket: Docket,
): { customerName: string; customerPhone: string } => {
  const { firstName = '', lastName = '', phone = '' } = docket.customer || {};
  return {
    customerName: [firstName, lastName].filter(x => !!x).join(' '),
    customerPhone: phone || '',
  };
};

export const getPrintableBuffer = (args: {
  originalDocket: Docket;
  template: PrinterTemplate;
  orderItems: OrderItemsPrintDetails[];
  title: String;
  allergens?: AllergensKey[];
  printerSeries: PrinterSeries;
  profileName?: string;
  session: Session;
}): Buffer | undefined => {
  const {
    originalDocket,
    template,
    orderItems,
    title,
    allergens = [],
    profileName,
    printerSeries,
    session,
  } = args;

  let customerInfo = '';
  const reasonOrNote = originalDocket.notes && `** ${originalDocket.notes} **`;
  const { firstName, lastName } = originalDocket.customer ?? {};
  if (firstName) {
    customerInfo = `CUSTOMER: ${firstName}${
      lastName ? ` ${lastName}` : ''
    }`.trim();
  }
  const tokenNumber = getTokenNumber(originalDocket);
  const timestamp = getDocketTimeStamp(originalDocket);

  const { customerName, customerPhone } = getCustomerInfo(originalDocket);
  const columns = getPrinterColumns(printerSeries, false);
  const data = {
    orderDetails: getTableDetail(originalDocket),
    orderName: originalDocket.customer.name || tokenNumber,
    orderItems,
    divider: divider(columns) + '\n',
    dashDivider: dashDivider(columns) + '\n',
    title: title + '\n',
    orderType: getOrderType(originalDocket, true),
    timeStamp:
      format(new Date(timestamp || new Date()), 'dd-MM-yyyy hh:mm a') + '\n',
    reasonOrNote,
    customerInfo,
    channel: getOrderChannel(originalDocket),
    printerProfile: !profileName ? profileName : profileName + '\n',
    kitchenBuzzer: session?.device?.deviceProfile?.enableKitchenBuzzer,
    ...(allergens.length && {
      allergens: allergens
        .map(a => `**ALLERGY ${ProductAllergens[a].toUpperCase()}**`)
        .join('\n'),
    }),
    customerName: customerName + '\n',
    customerPhone: customerPhone + '\n',
    printMode: printerSeries !== PrinterSeries.TM_U220 ? 'REST' : 'U220',
  };

  const printTemplate = nameToTemplate[template.name]
    ? nameToTemplate[template.name]
    : kitchenDocketTemplate;
  return EscPos.getBufferFromTemplate(printTemplate, data) as unknown as Buffer;
};
