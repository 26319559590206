import { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { useScale } from '../../hooks/useScale';
import theme from '../../common/theme';

const SegmentControlStyles = () => {
  const { scale } = useScale();
  return useMemo(
    () =>
      StyleSheet.create({
        container: {
          width: scale(400),
          padding: scale(4),
          flexDirection: 'row',
          alignItems: 'center',
          borderRadius: theme.radius.m,
          backgroundColor: theme.colors.black,
        },
        tab: {
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: theme.radius.s,
        },
        active: {
          backgroundColor: theme.colors.white,
        },
        tabText: {
          fontSize: scale(14),
          lineHeight: scale(22),
          paddingVertical: scale(8),
          color: theme.colors.grey5,
          fontFamily: theme.fonts.medium,
        },
        tabTextActive: {
          color: theme.colors.black1,
        },
      }),
    [scale],
  );
};

export default SegmentControlStyles;
