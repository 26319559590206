import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import { DocketStatus } from '@oolio-group/domain';
import theme from '../../../common/theme';

export interface DocketItemBaseProps {
  text: string;
  subtext: string;
  status: DocketStatus;
}

const DocketItemHeader: React.FC<DocketItemBaseProps> = ({
  text,
  subtext,
  status,
}) => {
  const { translate } = useTranslation();

  return (
    <View testID="docket-continue" style={styles.container}>
      <View
        style={[
          styles.light,
          {
            backgroundColor:
              status === DocketStatus.COMPLETED
                ? theme.colors.green
                : theme.colors.blue,
          },
        ]}
      />
      <Text style={styles.text}>{translate(`kitchenDisplay.${text}`)}</Text>
      <Text style={styles.text}>{subtext}</Text>
    </View>
  );
};

export default React.memo(DocketItemHeader);

const styles = StyleSheet.create({
  // eslint-disable-next-line react-native/no-color-literals
  container: {
    paddingRight: 14,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: theme.radius.m,
    backgroundColor: 'rgba(33, 150, 243, 0.3)',
  },
  light: {
    width: 4,
    height: '100%',
    borderRadius: 2,
    marginRight: 10,
  },
  text: {
    fontSize: 14,
    lineHeight: 14,
    paddingVertical: 12,
    color: theme.colors.white,
    fontFamily: theme.fonts.medium,
  },
});
