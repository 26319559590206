import React, { FC } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import FiltersTabStyles from './FiltersTab.styles';
import theme from '../../common/theme';
import Icon from '../Icon/Icon';

export interface FiltersTabProps {
  title: string;
  testID: string;
  subtitle?: string;
  loading?: boolean;
  checked?: boolean;
  onPress?: () => void;
  docketsCount?: number | undefined;
}

const FiltersTab: FC<FiltersTabProps> = ({
  title,
  testID,
  checked,
  onPress,
  docketsCount,
}) => {
  const styles = FiltersTabStyles();

  return (
    <TouchableOpacity
      testID={testID}
      onPress={onPress}
      style={[styles.container, checked && styles.containerSelected]}
    >
      <Text
        style={[
          styles.sortOptionTitle,
          checked && styles.sortOptionTitleChecked,
        ]}
      >
        {title}
      </Text>
      <View style={styles.accessory}>
        <Text
          style={[
            styles.sortOptionTitle,
            checked && styles.sortOptionTitleChecked,
          ]}
        >
          {docketsCount}
        </Text>
        {!checked ? (
          <Icon size={24} color={theme.colors.grey5} name={'angle-right'} />
        ) : null}
        {checked ? (
          <Icon name="check" size={24} color={theme.colors.green} />
        ) : null}
      </View>
    </TouchableOpacity>
  );
};

export default FiltersTab;
