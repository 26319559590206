import { gql } from '@apollo/client';

export const GET_SALES_CHANNELS = gql`
  query salesChannels {
    salesChannels {
      id
      name
      isActive
    }
  }
`;
