import React, { useCallback, useEffect, useState } from 'react';
import { InteractionManager } from 'react-native';
import { useSession } from '../../hooks/useSession';
import { useSettings } from '../../hooks/useSettings';
import { usePrinterProfiles } from '../../hooks/usePrinterProfiles';
import SelectPrinterProfileModal from '../../component/Modals/PrinterProfile/PrinterProfileModal';

const AssignPrinterProfile: React.FC = () => {
  const { session } = useSession();
  const { updatePrinterProfiles, loading } = useSettings();
  const { printerProfiles } = usePrinterProfiles();

  const [visible, setVisible] = useState(false);

  const hasPrinterProfilesAssigned =
    !!session?.kitchenDisplay?.printerProfiles?.length;

  useEffect(() => {
    const interactionPromise = InteractionManager.runAfterInteractions(() => {
      if (!hasPrinterProfilesAssigned) {
        setVisible(true);
      }
    });
    return () => interactionPromise.cancel();
  }, [hasPrinterProfilesAssigned]);

  const onCloseModal = useCallback(() => {
    setVisible(false);
  }, []);

  const onAssignProfiles = useCallback(
    (profileIDs: string[]) => {
      if (profileIDs.length) updatePrinterProfiles(profileIDs, onCloseModal);
    },
    [onCloseModal, updatePrinterProfiles],
  );

  return (
    <SelectPrinterProfileModal
      visible={visible}
      loading={loading}
      printerProfiles={printerProfiles}
      onSave={onAssignProfiles}
    />
  );
};

export default AssignPrinterProfile;
