import { gql } from '@apollo/client';

export const GET_PRINTER_PROFILES_QUERY = gql`
  query printerProfiles(
    $filter: PrinterProfilesFilterInput
    $limit: Int
    $skip: Int
  ) {
    printerProfiles(filter: $filter, limit: $limit, skip: $skip) {
      id
      name
      locale
      printerProfileType
      orderTypes
    }
  }
`;
