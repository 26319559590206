import { gql } from '@apollo/client';

export const KdsDeviceEvents = gql`
  subscription KdsDeviceEvents($storeId: String!, $kitchenDisplayId: String!) {
    kdsDeviceEvents(storeId: $storeId, kitchenDisplayId: $kitchenDisplayId) {
      kitchenDisplayId
      storeId
      organizationId
      action
      uuid
    }
  }
`;
