import React, { useMemo, useState } from 'react';
import {
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  ActivityIndicator,
} from 'react-native';
import { Store, Venue } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import theme from '../../common/theme';
import Icon from '../../component/Icon/Icon';
import Search from '../../component/Search/Search';
import styles from './DeviceSelection.styles';

interface VenueAndStoreListProps {
  onSelect?: (venue: Venue, store: Store) => void;
  onSearch?: (searchString: string) => void;
  venuesData: Venue[];
  loading?: boolean;
}

const LocationsList: React.FC<VenueAndStoreListProps> = ({
  onSelect = () => undefined,
  venuesData,
  loading = false,
}: VenueAndStoreListProps) => {
  const { translate } = useTranslation();

  const [searchText, setSearchText] = useState<string>('');

  const filteredVenues = useMemo(() => {
    return venuesData.filter(venue => {
      if (venue.name.toLowerCase().includes(searchText.toLowerCase()))
        return true;
      if (
        venue.stores.some(store =>
          store.name.toLowerCase().includes(searchText.toLowerCase()),
        )
      )
        return true;
    });
  }, [searchText, venuesData]);

  return (
    <View style={styles.content}>
      <Text style={styles.title}>
        {translate('assignRegister.selectStore')}
      </Text>
      <Search
        testID="search-locations"
        maxLength={50}
        onChangeText={setSearchText}
        placeholder={translate('backOfficeVenues.search')}
        containerStyle={styles.search}
      />
      {loading ? (
        <View style={styles.loading}>
          <ActivityIndicator size={20} color={theme.colors.primary} />
        </View>
      ) : (
        <View style={styles.table}>
          <ScrollView>
            {filteredVenues.length > 0 ? (
              filteredVenues.map(venue => (
                <View key={venue.id} testID="row-venue" style={styles.group}>
                  <View style={styles.rowParent}>
                    <Text style={styles.textParent}>{venue.name}</Text>
                  </View>
                  {venue.stores.map(store => (
                    <TouchableOpacity
                      key={store.id}
                      testID="row-store"
                      style={styles.rowChild}
                      onPress={() => onSelect(venue, store)}
                    >
                      <Icon
                        size={20}
                        color={theme.colors.grey5}
                        name="UilCornerDownRightAlt"
                      />
                      <Text style={styles.textChild}>{store.name}</Text>
                      <Icon
                        name="UilAngleRight"
                        size={20}
                        color={theme.colors.black1}
                      />
                    </TouchableOpacity>
                  ))}
                </View>
              ))
            ) : (
              <View style={styles.loading}>
                <Text style={styles.emptyText}>
                  {translate('DeviceSelection.noStores')}
                </Text>
              </View>
            )}
          </ScrollView>
        </View>
      )}
    </View>
  );
};

export default LocationsList;
