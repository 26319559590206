import palette from './colours.json';

export const themes = {
  light: {
    ...palette,
    text: palette.black1,
    textBody: palette.black2,
    background: palette.grey3,
    textLight: palette.grey6,
    textInverted: palette.white,
    elevation0: palette.grey2,
    elevation1: palette.white,
    elevation2: palette.grey2,
    dueText: palette.blue,
    dueView: palette.blueLight,
    voidedText: palette.red,
    voidedView: palette.redLight,
    transferredText: palette.pink,
    transferredView: palette.pinkLight,
    courseText: palette.blue,
    courseView: palette.blueLight,
    recalledText: palette.white,
    recalledView: palette.green,
    qtyView: palette.grey1,
    qtyViewInverted: 'rgba(0, 0, 0, 0.2)',
    noteText: palette.orangeDark,
    completedText: palette.orange,
    negativeLight: palette.redLightest,
    popupBg: palette.white,
    borderColor: palette.grey3,
    shadow: palette.black,
    notificationContainer: palette.red,
    notificationText: palette.white,
  },
  dark: {
    ...palette,
    text: palette.white,
    textBody: palette.grey5,
    background: palette.black2,
    textLight: palette.grey6,
    textInverted: palette.black1,
    elevation0: palette.tertiary1,
    elevation1: palette.tertiary2,
    elevation2: palette.tertiary3,
    dueText: palette.white,
    dueView: 'rgba(33, 150, 243, 0.3)',
    voidedText: palette.white,
    voidedView: 'rgba(244, 67, 54, 0.3)',
    transferredText: palette.white,
    transferredView: 'rgba(233, 30, 99, 0.3)',
    courseText: palette.white,
    courseView: 'rgba(33, 150, 243, 0.3)',
    recalledText: palette.white,
    recalledView: 'rgba(76, 175, 80, 0.3)',
    qtyView: 'rgba(0, 0, 0, 0.2)',
    qtyViewInverted: palette.grey1,
    noteText: palette.yellow,
    completedText: palette.orange,
    negativeLight: palette.redLightest,
    popupBg: palette.tertiary1,
    borderColor: palette.black1,
    shadow: palette.white,
    notificationContainer: palette.red,
    notificationText: palette.white,
  },
};

export type themeType = typeof themes.light;
