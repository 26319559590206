import React, { FC } from 'react';
import { Text, TouchableOpacity, ActivityIndicator } from 'react-native';
import { useColors } from '../../hooks/useColors';
import SettingsItemStyles from './SettingsItem.styles';
import theme from '../../common/theme';

interface SettingsActionProps {
  label: string;
  testID: string;
  loading?: boolean;
  onPress?: () => void;
  type: 'positive' | 'negative' | 'neutral';
}

const SettingsAction: FC<SettingsActionProps> = ({
  type,
  testID,
  label,
  loading,
  onPress,
}) => {
  const { colors } = useColors();
  const styles = SettingsItemStyles();

  return (
    <TouchableOpacity
      testID={testID}
      onPress={onPress}
      disabled={loading}
      style={[styles.container, styles.action]}
    >
      {!loading ? (
        <Text
          style={[
            styles.label,
            {
              color:
                type === 'positive'
                  ? theme.colors.green
                  : type === 'negative'
                  ? theme.colors.red
                  : theme.colors.blue,
            },
          ]}
        >
          {label}
        </Text>
      ) : (
        <ActivityIndicator color={colors.text} />
      )}
    </TouchableOpacity>
  );
};

export default SettingsAction;
