import { BehaviorSubject } from 'rxjs';
import { Platform } from 'react-native';
import PostHogProvider from './PostHogProvider';
import PosthogWebProvider from './PostHogWebProvider';

export enum POSTHOG_EVENT_ACTIONS {
  UPDATE_PRINTER_PROFILE = 'UPDATE_PRINTER_PROFILE',
  LOGOUT = 'LOGOUT',
  LOGIN = 'LOGIN',
  DEVICE_SELECTED = 'DEVICE_SELECTED',
  STORE_SELECTED = 'STORE_SELECTED',
  TOKEN_REFRESH_OR_EXPIRED = 'TOKEN_REFRESH_OR_EXPIRED',
}

export type POSTHOG_EVENT_DATA = {
  device?: string;
  printerProfiles?: { name: string; id: string }[];
  kdsName?: string;
  store?: string;
  venue?: string;
  orgId?: string;
  orgName?: string;
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface IAnalyticsProvider {
  init(): Promise<void>;
  identify(userId: string, properties: Record<string, any>): void;
  group(
    groupType: string,
    groupId: string,
    properties: Record<string, any>,
  ): void;
  capture(event: string, properties?: Record<string, any>): void;
  isFeatureEnabled(feature: string): Promise<boolean>;
  getAllFeatureFlags(): Promise<any>;
  startSessionRecording(): void;
  stopSessionRecording(): void;
  reset(): void;
}

export const analyticsInitSubject = new BehaviorSubject(null);

class AnalyticsService {
  private providers: IAnalyticsProvider[] = [];

  constructor(providers: IAnalyticsProvider[]) {
    this.providers = providers;
  }

  async init(): Promise<void> {
    await Promise.all(this.providers.map(provider => provider.init()));
    analyticsInitSubject.next(null);
  }

  identify(userId: string, properties: Record<string, any>): void {
    this.providers.forEach(provider => provider.identify(userId, properties));
  }

  group(
    groupType: string,
    groupId: string,
    properties: Record<string, any>,
  ): void {
    this.providers.forEach(provider =>
      provider.group(groupType, groupId, properties),
    );
  }

  capture(event: string, properties?: Record<string, any>): void {
    if (process.env.REACT_APP_ENABLE_TELEMETRY === 'true') {
      this.providers.forEach(provider => provider.capture(event, properties));
    } else {
      return;
    }
  }

  async isFeatureEnabled(feature: string): Promise<boolean> {
    const enabledFlags = await Promise.all(
      this.providers.map(async provider => {
        const isEnabled = await provider.isFeatureEnabled(feature);
        return isEnabled;
      }),
    );
    return enabledFlags.some(isEnabled => isEnabled);
  }

  async getAllFeatureFlags(): Promise<any> {
    const payloads = await Promise.all(
      this.providers.map(async provider => {
        const payload = await provider.getAllFeatureFlags();
        return payload;
      }),
    );
    return payloads;
  }

  startSessionRecording(): void {
    this.providers.forEach(provider => provider.startSessionRecording());
  }

  stopSessionRecording(): void {
    this.providers.forEach(provider => provider.stopSessionRecording());
  }

  reset(): void {
    this.providers.forEach(provider => provider.reset());
  }
}

let postHogProvider: IAnalyticsProvider;

if (Platform.OS === 'web') {
  postHogProvider = new PosthogWebProvider();
} else {
  postHogProvider = new PostHogProvider();
}

export const analyticsService = new AnalyticsService([postHogProvider]);

export default AnalyticsService;
