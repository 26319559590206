import { useCallback } from 'react';
import config from '../config';
import { useSession } from './useSession';
import { makeRequest } from '../utils/fetchHelper';
import { DefaultBumpMessage, DefaultReceiveMessage } from '../common/constants';
import { getItem } from '../storage/interface';
import { Docket, DocketStatus } from '@oolio-group/domain';
import { DOCKETS } from '../common/constants';

export interface UseOrderNotification {
  sendBumpSmsNotification: (ids: string[]) => void;
  sendReceiveSmsNotification: (ids: string[]) => void;
}

export function useOrderNotification(): UseOrderNotification {
  const { session } = useSession();
  const { bumpMessage, receiveMessage, notifyOnBump, notifyOnReceive } =
    session.kitchenDisplay?.notificationConfig || {};

  const invokeBumpNotification = useCallback(
    async (id: string) => {
      const dockets =
        ((await getItem(DOCKETS + `#${DocketStatus.COMPLETED}`)) as Docket[]) ||
        [];

      const docket = dockets.find((item: Docket) => item.id == id);
      if (docket) {
        const input = {
          ids: [id],
          smsBody: bumpMessage || DefaultBumpMessage,
          action: 'BUMPED',
        };
        await makeRequest(
          config.KDS_SERVICE_URI + '/docket/customer-notify',
          input,
        );
      }
    },
    [bumpMessage],
  );

  const sendBumpSmsNotification = useCallback(
    async (ids: string[]) => {
      if (!notifyOnBump) return;
      setTimeout(() => {
        invokeBumpNotification(ids[0] ?? '');
      }, 5000);
    },
    [invokeBumpNotification, notifyOnBump],
  );

  const sendReceiveSmsNotification = useCallback(
    async (ids: string[]) => {
      if (!notifyOnReceive) return;
      const input = {
        ids: ids,
        smsBody: receiveMessage || DefaultReceiveMessage,
        action: 'CREATED',
      };
      await makeRequest(
        config.KDS_SERVICE_URI + '/docket/customer-notify',
        input,
      );
    },
    [notifyOnReceive, receiveMessage],
  );

  return {
    sendBumpSmsNotification,
    sendReceiveSmsNotification,
  };
}
