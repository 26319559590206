import { useCallback, useMemo, useRef } from 'react';
import { GET_SETTINGS_QUERY } from '../graphql/settings';
import { useLazyQuery } from '@apollo/client';
import { noopHandler } from '../utils/general';
import { setItem } from '../storage/interface';
import {
  ORG_TIMINGS_STORAGE_KEY,
  ORDER_TYPES_STORAGE_KEY,
  SALES_CHANNEL_STORAGE_KEY,
} from '../common/constants';
import { DateTime, OrderType, SalesChannel } from '@oolio-group/domain';
import { useRestart } from './useRestart';

type settingsData = {
  orderTypes: OrderType[];
  dateTime: DateTime;
  salesChannels: SalesChannel[];
};
export function useFetchSettings() {
  const { restartDevice } = useRestart();
  const restartAppOnSettings = useRef(false);
  const setSettingsDataLocally = async (data: settingsData) => {
    if (data) {
      await setItem(ORDER_TYPES_STORAGE_KEY, data?.orderTypes);
      await setItem(ORG_TIMINGS_STORAGE_KEY, data?.dateTime);
      await setItem(SALES_CHANNEL_STORAGE_KEY, data?.salesChannels);
      if (restartAppOnSettings.current) restartDevice();
    }
  };
  const [getSettings, getOrgTimingsResponse] = useLazyQuery(
    GET_SETTINGS_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: setSettingsDataLocally,
      onError: noopHandler,
    },
  );

  const fetchSettings = useCallback(
    (refershApp?: boolean) => {
      if (refershApp) restartAppOnSettings.current = true;
      getSettings();
    },
    [getSettings],
  );

  return useMemo(
    () => ({
      loading: getOrgTimingsResponse.loading,
      fetchSettings,
    }),
    [fetchSettings, getOrgTimingsResponse.loading],
  );
}
