import { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { useScale } from '../../hooks/useScale';
import theme from '../../common/theme';

const ButtonStyles = () => {
  const { scale } = useScale();

  return useMemo(
    () =>
      StyleSheet.create({
        container: {
          height: scale(44),
          minWidth: scale(44),
          paddingHorizontal: scale(12),
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: theme.radius.m,
        },
        text: {
          fontSize: scale(14),
          lineHeight: scale(20),
          fontFamily: theme.fonts.medium,
        },
        icon: {
          marginLeft: 8,
        },
      }),
    [scale],
  );
};

export default ButtonStyles;
