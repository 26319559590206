import React, { useState, useEffect } from 'react';
import { Text } from 'react-native';
import { DocketStatus } from '@oolio-group/domain';
import {
  getTimeDifference,
  minifyHourText,
  minifyMinuteText,
} from '../../../utils/timeHelper';
import moment from 'moment';
import DocketItemStyles from '../DocketItem.styles';

export interface DocketItemTimerProps {
  createdTime: string;
  status: DocketStatus;
}

const DocketItemTimer: React.FC<DocketItemTimerProps> = ({
  createdTime,
  status,
}) => {
  const styles = DocketItemStyles();
  const [time, setTime] = useState(Date.now());

  useEffect(() => {
    if (status == DocketStatus.CREATED) {
      const interval = setInterval(() => setTime(Date.now()), 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [status]);

  const duration =
    status == DocketStatus.CREATED
      ? getTimeDifference(time, Number(createdTime))
      : minifyMinuteText(minifyHourText(moment(createdTime).fromNow()));

  return (
    <Text testID="docket-duration" style={styles.text}>
      {duration}
    </Text>
  );
};

export default DocketItemTimer;
