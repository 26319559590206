export const kitchenDocketTemplate = `
<?xml version="1.0" encoding="UTF-8"?>
<document>
  <print-mode mode="{{printMode}}" />
  {{#title}}
  <align mode="center">
    <text-line size="0:1">{{title}}</text-line>
  </align>
  {{/title}}
  {{#printerProfile}}
  <align mode="center">
    <text-line size="1:1">{{printerProfile}}</text-line>
  </align>
  {{/printerProfile}}
  {{#timeStamp}}
  <align mode="center">
    <text-line size="0:0">{{timeStamp}}</text-line>
  </align>
  {{/timeStamp}}
  {{#divider}}
  <align mode="center">
    <text-line size="0:0">{{{divider}}}</text-line>
    <line-feed />
  </align>
  {{/divider}}
  {{#channel}}
  <align mode="center">
    <text-line size="0:1">{{channel}}</text-line>
    <text-line size="0:0">{{{divider}}}</text-line>
    <line-feed />
  </align>
  {{/channel}}
  <align mode="center">
    <bold>
      <text-line size="1:1">{{orderType}}</text-line>
    </bold>
  </align>
  {{#orderName}}
  <align mode="center">
    <text-line size="0:0">{{{divider}}}</text-line>
    <text-line size="1:1">{{orderName}}</text-line>
    <text-line size="0:0">{{{divider}}}</text-line>
  </align>
  {{/orderName}}
  <line-feed />
  <align mode="center">
    <bold>
      <text-line size="0:0">{{orderDetails}}</text-line>
    </bold>
  </align>
  {{#reasonOrNote}}
  <align mode="center">
    <bold>
      <text-line size="0:1">{{customerInfo}}</text-line>
      <text-line size="0:1">{{reasonOrNote}}</text-line>
      <text-line>{{{divider}}}</text-line>
    </bold>
  </align>
  {{/reasonOrNote}}
  {{#allergens}}
  <bold>
    <text-line>{{{divider}}}</text-line>
    <text-line color="red">{{allergens}}</text-line>
  </bold>
  <line-feed />
  {{/allergens}}
  {{#orderItems}}
  <align mode="center">
    {{#header}}
    <bold>
      <text-line size="0:0">{{header}}</text-line>
    </bold>
    <text-line size="0:0">{{dashDivider}}</text-line>
    {{/header}}
    {{#groupedItems}}
    {{#seat}}
    <line-feed />
    <text-line size="0:0">{{dashDivider}}</text-line>
    <bold>
      <text-line size="0:1">{{seat}}</text-line>
    </bold>
    {{/seat}}
    {{#seatAllergens}}
    <line-feed />
    <bold>
      <text-line color="red">{{seatAllergens}}</text-line>
    </bold>
    {{/seatAllergens}}
    {{#items}}
    <line-feed />
    <bold>
      <text-line size="1:1">{{product}}</text-line>
    </bold>
    {{#note}}
    <bold>
      <text-line size="1:0">{{note}}</text-line>
    </bold>
    {{/note}}
    {{#modifiers}}
    <bold>
      <text-line size="0:1">{{modifiers}}</text-line>
    </bold>
    {{/modifiers}}
    {{/items}}
    {{/groupedItems}}
    <text-line size="0:0">{{dashDivider}}</text-line>
    </align>
  {{/orderItems}}
  <align mode="center">
    {{#customerName}}
    <text-line size="0:1">{{customerName}}</text-line>
    {{/customerName}}
    {{#customerPhone}}
    <text-line size="0:1">{{customerPhone}}</text-line>
    {{/customerPhone}}
    {{#customerAddress}}
    <text-line size="0:1">{{customerAddress}}</text-line>
    {{/customerAddress}}
  </align>
  <paper-cut />
  {{#kitchenBuzzer}}
  <open-cash-drawer></open-cash-drawer>
  {{/kitchenBuzzer}}
</document>
`;

export const smlkitchenDocketTemplate = `
<?xml version= "1.0" encoding= "UTF-8"?>
<document>
  <print-mode mode= {{printMode}} />
  <align mode= "center">
    <text-line size= "0:0">{{title}}</text-line>
    {{#printerProfile}}
    <text-line size= "1:0">{{printerProfile}}</text-line>
    {{/printerProfile}}
    <text-line size= "0:0">{{timeStamp}}</text-line>
  </align>
  <align mode= "center">
    <text-line size= "0:0">{{{divider}}}</text-line>
    <line-feed />
  </align>
  {{#channel}}
    <align mode= "center">
      <text-line size= "0:0">{{channel}}</text-line>
      <text-line size= "0:0">{{{divider}}}</text-line>
      <line-feed />
    </align>
  {{/channel}}
  <align mode= "center">
    <bold>
      <text-line size= "1:0">{{orderType}}</text-line>
    </bold>
  </align>
  {{#orderName}}
    <align mode= "center">
      <text-line size= "0:0">{{{divider}}}</text-line>
      <text-line size= "1:0">{{orderName}}</text-line>
      <text-line size= "0:0">{{{divider}}}</text-line>
    </align>
  {{/orderName}}
  <line-feed />
  <align mode= "center">
    <bold>
      <text-line size= "0:0">{{orderDetails}}</text-line>
    </bold>
  </align>
  <align mode= "center">
    <text-line>{{{divider}}}</text-line>
  </align>
  <align mode= "center">
    <line-feed />
    {{#customerInfo}}
    <text-line size= "0:0">{{customerInfo}}</text-line>
    <line-feed />
    {{/customerInfo}}
    {{#reasonOrNote}}
      <bold>
        <text-line size= "0:0">{{reasonOrNote}}</text-line>
      </bold>
    <line-feed />
    {{/reasonOrNote}}
    {{#allergens}}
    <bold>
      <text-line>{{{divider}}}</text-line>
      <text-line color="red">{{allergens}}</text-line>
    </bold>
    <line-feed />
    {{/allergens}}
    <text-line>{{{divider}}}</text-line>
  </align>
  {{#orderItems}}
    {{#header}}
      <align mode= "center">
        <bold>
          <text-line size= "0:0">{{header}}</text-line>
        </bold>
        <text-line size= "0:0">{{dashDivider}}</text-line>
      </align>
    {{/header}}
      {{#groupedItems}}
      <line-feed />
        {{#seat}}
          <align mode= "center">
            <bold>
              <text-line size= "0:0">{{seat}}</text-line>
            </bold>
          </align>
        {{/seat}}
        {{#seatAllergens}}
        <line-feed />
        <bold>
          <text-line color="red">{{seatAllergens}}</text-line>
        </bold>
        {{/seatAllergens}}
        {{#items}}
          <line-feed />
          <align mode= "center">
            <bold>
              <text-line size= "1:0">{{product}}</text-line>
            </bold>
            {{#note}}
            <bold>
            <text-line size= "1:0">{{note}}</text-line>
            </bold>
            {{/note}}
            {{#modifiers}}
            <bold>
            <text-line size= "0:0">{{modifiers}}</text-line>
            </bold>
            {{/modifiers}}
          </align>
        {{/items}}
        <text-line size= "0:0">{{dashDivider}}</text-line>
      {{/groupedItems}}
    <align mode= "center">
    </align>
  {{/orderItems}}
  <align mode= "center">
    {{#customerName}}
      <text-line size= "0:0">{{customerName}}</text-line>
    {{/customerName}}
    {{#customerPhone}}
      <text-line size= "0:0">{{customerPhone}}</text-line>
    {{/customerPhone}}
    {{#customerAddress}}
      <text-line size= "0:0">{{customerAddress}}</text-line>
    {{/customerAddress}}
  </align>
  <paper-cut />
  {{#kitchenBuzzer}}
    <open-cash-drawer></open-cash-drawer>
  {{/kitchenBuzzer}}
</document>
`;

export const lrgKitchenDocketTemplate = `
  <?xml version= "1.0" encoding= "UTF-8"?>
  <document>
    <print-mode mode= {{printMode}} />
    <align mode= "center">
      <text-line size= "0:1">{{title}}</text-line>
      {{#printerProfile}}
      <text-line size= "1:1">{{printerProfile}}</text-line>
      {{/printerProfile}}
      <text-line size= "0:0">{{timeStamp}}</text-line>
    </align>
    <align mode= "center">
      <text-line size= "0:0">{{{divider}}}</text-line>
      <line-feed />
    </align>
    {{#channel}}
      <align mode= "center">
        <text-line size= "0:1">{{channel}}</text-line>
        <text-line size= "0:0">{{{divider}}}</text-line>
        <line-feed />
      </align>
    {{/channel}}
    <align mode= "center">
      <bold>
        <text-line size= "1:1">{{orderType}}</text-line>
      </bold>
    </align>
    {{#orderName}}
      <align mode= "center">
        <text-line size= "0:0">{{{divider}}}</text-line>
        <text-line size= "1:1">{{orderName}}</text-line>
        <text-line size= "0:0">{{{divider}}}</text-line>
      </align>
    {{/orderName}}
    <line-feed />
    <align mode= "center">
      <bold>
        <text-line size= "0:0">{{orderDetails}}</text-line>
      </bold>
    </align>
    <align mode= "center">
      <text-line>{{{divider}}}</text-line>
    </align>
    <align mode= "center">
    <line-feed />
    {{#customerInfo}}
    <text-line size= "1:1">{{customerInfo}}</text-line>
    <line-feed />
    {{/customerInfo}}
    {{#reasonOrNote}}
      <bold>
        <text-line size= "1:1">{{reasonOrNote}}</text-line>
      </bold>
    <line-feed />
    {{/reasonOrNote}}
    {{#allergens}}
    <bold>
      <text-line>{{{divider}}}</text-line>
      <text-line color="red">{{allergens}}</text-line>
    </bold>
    <line-feed />
    {{/allergens}}
    <text-line>{{{divider}}}</text-line>
    </align>
    {{#orderItems}}
      {{#header}}
        <align mode= "center">
          <bold>
            <text-line size= "0:0">{{header}}</text-line>
          </bold>
          <text-line size= "0:0">{{dashDivider}}</text-line>
        </align>
      {{/header}}
        {{#groupedItems}}
          {{#seat}}
          <line-feed />
            <align mode= "center">
            <text-line size= "0:0">{{dashDivider}}</text-line>
              <bold>
                <text-line size= "0:1">{{seat}}</text-line>
              </bold>
            </align>
          {{/seat}}
          {{#seatAllergens}}
          <line-feed />
          <bold>
            <text-line color="red">{{seatAllergens}}</text-line>
          </bold>
          {{/seatAllergens}}
          {{#items}}
            <line-feed />
            <align mode= "center">
              <bold>
                <text-line size= "1:1">{{product}}</text-line>
              </bold>
              {{#note}}
              <bold>
              <text-line size= "1:1">{{note}}</text-line>
              </bold>
              {{/note}}
              {{#modifiers}}
              <bold>
              <text-line size= "1:1">{{modifiers}}</text-line>
              </bold>
              {{/modifiers}}
            </align>
          {{/items}}
        {{/groupedItems}}
      <align mode= "center">
        <text-line size= "0:0">{{dashDivider}}</text-line>
      </align>
    {{/orderItems}}
    <align mode= "center">
      {{#customerName}}
        <text-line size= "0:1">{{customerName}}</text-line>
      {{/customerName}}
      {{#customerPhone}}
        <text-line size= "0:1">{{customerPhone}}</text-line>
      {{/customerPhone}}
      {{#customerAddress}}
        <text-line size= "0:1">{{customerAddress}}</text-line>
      {{/customerAddress}}
    </align>
    <paper-cut />
    {{#kitchenBuzzer}}
      <open-cash-drawer></open-cash-drawer>
    {{/kitchenBuzzer}}
  </document>
`;

export const kitchenDocketLabelTemplate = `
<?xml version="1.0" encoding="UTF-8"?>
<document>
  <print-mode mode="{{printMode}}" />
  {{#title}}
  <align mode="center">
    <text-line size="0:0">{{title}}</text-line>
  </align>
  {{/title}}

  {{#divider}}
  <align mode="center">
    <text-line size="0:0">{{{divider}}}</text-line>
  </align>
  {{/divider}}

  {{#orderName}}
  <align mode="center">
    <text-line size="1:1">{{orderName}}</text-line>
  </align>
  {{/orderName}}
  <line-feed />
  {{#orderType}}
  <align mode="center">
    <bold>
      <text-line size="0:0">{{orderType}}</text-line>
    </bold>
  </align>
   {{/orderType}}

    {{#divider}}
  <align mode="center">
    <text-line size="0:0">{{{divider}}}</text-line>
  </align>
  {{/divider}}

  {{#orderItems}}
  <align mode="center">
    {{#groupedItems}}
    {{#seat}}
    <bold>
      <text-line size="0:1">{{seat}}</text-line>
    </bold>
    {{/seat}}
    {{#seatAllergens}}
    <line-feed />
    <bold>
      <text-line color="red">{{seatAllergens}}</text-line>
    </bold>
    {{/seatAllergens}}
    {{#items}}
    <line-feed />
    <bold>
      <text-line size="1:1">{{product}}</text-line>
    </bold>
    {{#note}}
    <bold>
      <text-line size="1:0">{{note}}</text-line>
    </bold>
    {{/note}}
    {{#modifiers}}
    <bold>
      <text-line size="0:1">{{modifiers}}</text-line>
    </bold>
    {{/modifiers}}
    {{/items}}
    {{/groupedItems}}
    </align>
  {{/orderItems}}


  {{#divider}}
  <align mode="center">
    <text-line size="0:0">{{{divider}}}</text-line>
  </align>
  {{/divider}}

    {{#footer}}
  <align mode="center">
    <text-line size="0:0">{{footer}}</text-line>
    <line-feed />
  </align>
  {{/footer}}
  <paper-cut />
</document>
`;
