import React from 'react';
import { View, Text } from 'react-native';
import DocketItemStyles from '../DocketItem.styles';

export interface DocketItemBaseProps {
  name: string;
}

const DocketItemNotes: React.FC<DocketItemBaseProps> = ({ name }) => {
  const styles = DocketItemStyles();

  return (
    <View
      testID="item-note"
      style={[styles.statusContainer, styles.noteContainer]}
    >
      <Text style={styles.statusText}>{name}</Text>
    </View>
  );
};

export default React.memo(DocketItemNotes);
