import { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { useScale } from '../../hooks/useScale';
import theme from '../../common/theme';

const LoginStyles = () => {
  const { scale } = useScale();

  return useMemo(
    () =>
      StyleSheet.create({
        screen: {
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
        },
        container: {
          width: scale(700),
          padding: scale(40),
          alignItems: 'center',
          borderRadius: theme.radius.l,
          backgroundColor: theme.colors.white,
          ...theme.shadow.strong,
        },
        modalContainer: {
          width: scale(700),
          padding: scale(60),
          alignItems: 'center',
          borderRadius: theme.radius.m,
          backgroundColor: theme.colors.white,
          ...theme.shadow.strong,
        },
        title: {
          flex: 1,
          fontSize: scale(18),
          lineHeight: scale(26),
          textAlign: 'center',
          fontFamily: theme.fonts.semibold,
        },
        qrContainer: {
          width: scale(180),
          height: scale(180),
          marginTop: scale(40),
          alignItems: 'center',
          justifyContent: 'center',
        },
        btnRefresh: {
          position: 'absolute',
          right: -54,
        },
        codeContainer: {
          width: scale(180),
          padding: scale(10),
          marginTop: scale(20),
          borderRadius: theme.radius.s,
          backgroundColor: theme.colors.grey1,
        },
        code: {
          fontSize: scale(22),
          lineHeight: scale(38),
          letterSpacing: 1,
          textAlign: 'center',
          fontFamily: theme.fonts.bold,
        },
        footnote: {
          fontSize: scale(14),
          lineHeight: scale(26),
          textAlign: 'center',
          marginTop: scale(40),
          maxWidth: scale(500),
          color: theme.colors.grey6,
        },
      }),
    [scale],
  );
};

export default LoginStyles;
