import { Docket, DocketItem, DocketItemStatus } from '@oolio-group/domain';
import { AllDayViewItem } from '../../common/types';

export function groupDocketItems(dockets: Docket[]) {
  const allDayData: AllDayViewItem[] = [];

  //removing dockets when there are no docket items
  const filteredDockets = dockets.filter(docket => docket.docketItems);

  const getGroupingKey = (item: DocketItem): string => {
    return [
      item.product ? item.product.id || '' : '',
      item.course ? item.course.id || '' : '',
      item.variant ? item.variant.id || '' : '',
      JSON.stringify(item.modifiers || []),
    ].join('#');
  };

  const groupedData = filteredDockets
    .flatMap(docket =>
      docket.docketItems.filter(
        item =>
          item.itemStatus === DocketItemStatus.CREATED ||
          item.itemStatus === DocketItemStatus.RECALLED,
      ),
    )
    .reduce((grouped, item) => {
      const key = getGroupingKey(item);
      grouped.set(key, [...(grouped.get(key) || []), item]);
      return grouped;
    }, new Map<string, DocketItem[]>());

  groupedData.forEach(group => {
    const obj: AllDayViewItem = {
      product: group[0].product?.name || '',
      variation: group[0].variant?.name || '',
      quantity: group.reduce((sum, item) => sum + (item.quantity || 0), 0),
      course: group[0].course?.name || '',
      modifiers: group[0].modifiers?.map(mod => mod.name ?? '') || [],
      docketItemIds: group.map(item => item.id),
      orderItemIds: group
        .filter(item => !!item?.orderItemId)
        .map(item => item.orderItemId) as string[],
    };
    allDayData.push(obj);
  });

  return allDayData.sort((a, b) => (b.quantity ?? 1) - (a?.quantity ?? 1));
}
