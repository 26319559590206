import React from 'react';
import { View, Text } from 'react-native';
import DocketItemStyles from '../DocketItem.styles';
import { useSession } from '../../../hooks/useSession';
import { keyBy } from 'lodash';
import { useTranslation } from '@oolio-group/localization';
import { DocketDisplayItemType } from '../../../common/types';

export interface DocketItemBaseProps {
  tableId: string;
  transferLabelType?: DocketDisplayItemType;
}

const DocketItemNotes: React.FC<DocketItemBaseProps> = ({
  tableId,
  transferLabelType = DocketDisplayItemType.TRANSFERRED_TO_LABEL,
}) => {
  const styles = DocketItemStyles();
  const { session } = useSession();
  const { sections } = session;
  const { translate } = useTranslation();

  const tables = keyBy(
    sections?.flatMap(section => section?.tables) ?? [],
    'id',
  );

  const table = tables[tableId];
  const tableName = table?.name ? `(${table.name})` : '';

  const isTransferredFrom =
    transferLabelType === DocketDisplayItemType.TRANSFERRED_FROM_LABEL;

  const label = isTransferredFrom
    ? `${translate('kitchenDisplay.transferredFrom', {
        tableName,
      })}`
    : `${translate('kitchenDisplay.transferredTo', {
        tableName,
      })}`;

  return (
    <View
      testID="item-note"
      style={[styles.statusContainer, styles.transferredContainer]}
    >
      <Text style={[styles.statusText, styles.transferredText]}>{label}</Text>
    </View>
  );
};

export default React.memo(DocketItemNotes);
