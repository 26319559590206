import { gql } from '@apollo/client';

export const GET_ORDER_TYPES_QUERY = gql`
  query getOrderTypes {
    orderTypes {
      id
      name
      code
      isActive
    }
  }
`;
