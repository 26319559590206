import React, { FC } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { DocketStatus } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { SegmentControlTabs } from '../../common/types';
import SegmentControlStyles from './SegmentControl.styles';

interface SegmentControlProps {
  testID: string;
  tabs: Array<SegmentControlTabs>;
  selectedTab: string;
  onChange: (tab: DocketStatus) => void;
}

const SegmentControl: FC<SegmentControlProps> = ({
  tabs,
  testID,
  selectedTab,
  onChange,
}) => {
  const styles = SegmentControlStyles();
  const { translate } = useTranslation();

  return (
    <View testID={testID} style={styles.container}>
      {tabs?.map((tab, i) => (
        <TouchableOpacity
          key={i}
          testID={`segmentTab-${i}`}
          style={[styles.tab, selectedTab === tab.value && styles.active]}
          onPress={() => onChange(tab.value as DocketStatus)}
        >
          <Text
            style={[
              styles.tabText,
              selectedTab === tab.value && styles.tabTextActive,
            ]}
          >
            {translate(`kitchenDisplay.${tab.label}`) || tab.label}
          </Text>
        </TouchableOpacity>
      ))}
    </View>
  );
};

export default SegmentControl;
