import fetch from 'cross-fetch';
import { PRINT_ERROR_MESSAGES } from '../../utils/Commons';

const PRINT_TIMEOUT = 60000;

export enum XML_COMMAND {
  STATUS = 'status',
  RECOVER = 'recover',
  RESET = 'reset',
}

const getURL = (host: string, timeout?: number, slug?: string) => {
  if (slug) {
    return `${process.env.REACT_APP_PRINTER_SIM_URL}/api/${slug}`;
  } else
    return `https://${host}/cgi-bin/epos/service.cgi?devid=local_printer&timeout=${
      timeout || PRINT_TIMEOUT
    }`;
};

export type PrinterResponse = {
  success: boolean;
  message: string;
  responseSuccess?: string | null;
  responseCode?: string | null;
  responseStatus?: string | null;
};

export const connectAndSend = async (
  host: string,
  message?: Buffer,
  options?: { timeout?: number },
  command?: XML_COMMAND,
  slug?: string,
): Promise<PrinterResponse> => {
  try {
    let xmlCommand;

    switch (command) {
      case XML_COMMAND.STATUS:
        xmlCommand = '><command>0100000000010000000001</command>';
        break;
      case XML_COMMAND.RECOVER:
        xmlCommand = ' force="true"><recovery />';
        break;
      case XML_COMMAND.RESET:
        xmlCommand = '><reset />';
        break;
      default:
        if (message) {
          xmlCommand = `><command>${message.toString('hex')}</command>`;
        }
        break;
    }
    const data = `<?xml version="1.0" encoding="utf-8"?><s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/"><s:Body><epos-print xmlns="http://www.epson-pos.com/schemas/2011/03/epos-print"${xmlCommand}</epos-print></s:Body></s:Envelope>`;

    const controller = new AbortController();
    const { signal } = controller;

    const fetchPromise = fetch(getURL(host, options?.timeout, slug), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/xml',
      },
      body: data,
      signal,
    });
    // Timeout is for printer connection, not printer response
    const timeoutPromise = new Promise((_, reject) => {
      const id = setTimeout(() => {
        controller.abort();
        reject(new Error('Request timed out'));
      }, 300000);
      // Clear timeout if the request is successful
      fetchPromise.then(() => clearTimeout(id));
    });

    const res = (await Promise.race([
      fetchPromise,
      timeoutPromise,
    ])) as Response;

    const result = await res.text();
    const regex =
      /.*response success="(.*)" code="(.*)" status="(.*)" battery="(.*)" xmlns.*/g;
    const parsedResponse = regex.exec(result);
    if (parsedResponse && parsedResponse[1] === 'false') {
      const error = parsedResponse[2];

      return {
        success: false,
        message: PRINT_ERROR_MESSAGES[String(error)] || error || 'Error',
        responseSuccess: parsedResponse?.[1],
        responseCode: parsedResponse?.[2],
        responseStatus: parsedResponse?.[3],
      };
    }

    return {
      success: true,
      message: 'Sent to printer',
      responseSuccess: parsedResponse?.[1],
      responseCode: parsedResponse?.[2],
      responseStatus: parsedResponse?.[3],
    };
  } catch (error) {
    return {
      success: false,
      message:
        'Failed to connect - check network status or installed certificates' ||
        'Unknown Error',
    };
  }
};
