import { gql } from 'graphql-tag';

export const FRAGMENTS = {
  sectionDetails: gql`
    fragment SectionDetails on Section {
      id
      name
      tables {
        id
        name
      }
    }
  `,
};

export const GET_SECTIONS = gql`
  query sectionsByVenueId($id: ID!) {
    sections(venueId: $id) {
      ...SectionDetails
    }
  }
  ${FRAGMENTS.sectionDetails}
`;
