import { Dimensions } from 'react-native';
import palette from './colours.json';

// Dimensions

const { width: vw, height: vh } = Dimensions.get('window');

// Fonts

const colors = {
  ...palette,
  modalBg: 'rgba(0, 0, 0, 0.8)',
  gradient: `linear-gradient(to bottom right, ${palette.teal}, ${palette.greenDark})`,
};

const fonts = {
  regular: 'Inter-Regular',
  medium: 'Inter-Medium',
  semibold: 'Inter-Semibold',
  bold: 'Inter-Bold',
};

// Shadows

const shadow = {
  light: {
    shadowColor: palette.black,
    shadowOpacity: 0.15,
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 6,
    elevation: 3,
  },
  strong: {
    shadowColor: palette.black,
    shadowOpacity: 0.3,
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 6,
    elevation: 6,
  },
};

// Border Radii

const radius = {
  s: 4,
  m: 6,
  l: 10,
};

const elevations = {
  sidebar: 2,
};

export default {
  fonts,
  shadow,
  radius,
  colors,
  window: {
    vw,
    vh,
  },
  elevations,
};
