import { Docket, DocketItemStatus } from '@oolio-group/domain';

export const detectIncomingDocketThroughPolling = (
  docketsReceived: Docket[],
  docketsInLocal: Docket[],
) => {
  const newDocketsReceived = docketsReceived
    .filter(docket => docket?.itemStatus !== DocketItemStatus.RECALLED)
    .map(docket => docket?.id);
  if (
    !newDocketsReceived.reduce(
      (a, b) => a && docketsInLocal.map(docket => docket?.id).includes(b),
      true,
    )
  ) {
    return true;
  }
  return false;
};
