import React from 'react';
import { View, StyleSheet, ViewStyle, Platform } from 'react-native';
import theme from '../../common/theme';

interface GradientProps {
  children: React.ReactNode;
  containerStyle?: ViewStyle | ViewStyle[];
}

const Gradient: React.FC<GradientProps> = ({ children, containerStyle }) => {
  return <View style={[styles.gradient, containerStyle]}>{children}</View>;
};

const styles = StyleSheet.create({
  gradient: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: theme.colors.gradient,
    backgroundColor:
      Platform.OS === 'web' ? theme.colors.transparent : theme.colors.greenDark,
  },
});

export default Gradient;
