import React from 'react';
import { ScrollView, Text, TouchableOpacity, View } from 'react-native';
import Icon from '../Icon/Icon';
import { useColors } from '../../hooks/useColors';
import SidebarStyles from './Sidebar.styles';

interface SidebarProps {
  onClose: () => void;
  title: string;
  onPressRight?: () => void;
  buttonRight?: React.ReactNode | React.ReactNode[];
  children: React.ReactNode | React.ReactNode[];
  action?: React.ReactNode | React.ReactNode[];
}

const Sidebar: React.FC<SidebarProps> = ({
  onClose,
  title,
  onPressRight,
  buttonRight,
  children,
  action,
}: SidebarProps) => {
  const styles = SidebarStyles();
  const { colors } = useColors();

  return (
    <View style={styles.sidebar}>
      <View style={styles.titleContainer}>
        <View style={styles.titleTextContainer}>
          <TouchableOpacity onPress={onClose}>
            <Icon name={'times'} color={colors.text} size={24} />
          </TouchableOpacity>
          <Text style={styles.titleText}>{title}</Text>
        </View>
        {!!buttonRight && (
          <TouchableOpacity onPress={onPressRight}>
            {buttonRight}
          </TouchableOpacity>
        )}
      </View>
      <ScrollView
        style={styles.scrollContainer}
        contentContainerStyle={styles.content}
        showsVerticalScrollIndicator={false}
      >
        {children}
      </ScrollView>
      {!!action && <View style={styles.actions}>{action}</View>}
    </View>
  );
};

export default Sidebar;
