import React from 'react';
import { View, Text } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import { RECALLED_TEXT } from '../../../common/constants';
import DocketItemStyles from '../DocketItem.styles';

const DocketRecalledLabel: React.FC = () => {
  const styles = DocketItemStyles();
  const { translate } = useTranslation();
  return (
    <View
      testID="docket-recalled"
      style={[styles.statusContainer, styles.recalledContainer]}
    >
      <Text style={[styles.statusTextUppercase, styles.recalledText]}>
        {translate(`kitchenDisplay.${RECALLED_TEXT}`)}
      </Text>
    </View>
  );
};

export default React.memo(DocketRecalledLabel);
