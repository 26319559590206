export const PRINT_ERROR_MESSAGES: Record<string, string> = {
  EPTR_AUTOMATICAL: 'Automatic recovery error occurred',
  EPTR_BATTERY_LOW: 'Battery has run out',
  EPTR_COVER_OPEN: 'Cover open error occurred',
  EPTR_CUTTER: 'Auto cutter error occurred',
  EPTR_MECHANICAL: 'Mechanical error occurred',
  EPTR_REC_EMPTY: 'No paper is left in the roll paper end detector',
  EPTR_UNRECOVERABLE: 'Unrecoverable error occurred',
  SchemaError: 'Error exists in the requested document syntax',
  DeviceNotFound: 'Printer specified by the device ID does not exist',
  PrintSystemError: 'Error occurred with the printing system',
  EX_BADPORT: 'An error occurred with the communication port',
  EX_TIMEOUT: 'Print timeout occurred',
  EX_SPOOLER: 'Print queue is full',
  JobNotFound: 'Specified job ID does not exist',
  Printing: 'Printing in progress',
  JobSpooling: 'Job is spooling.',
  TooManyRequests:
    'The number of print jobs sent to the printer has exceeded the allowable limit.',
  RequestEntityTooLarge:
    'The size of the print job data exceeds the capacity of the printer.',
  ERROR_WAIT_EJECT: 'Waiting for paper removal.',
};
