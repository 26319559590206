import { useEffect, useMemo, useRef } from 'react';
import { Howl } from 'howler';

export function useAudioNotification() {
  const audio = useRef(
    new Howl({
      src: ['/notification.mp3'],
      loop: false,
    }),
  ).current;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const delayTimeout = useRef<any>();
  const handler = useMemo(() => {
    return {
      play(delayTime?: number) {
        if (audio.state() !== 'loaded') return;
        delayTimeout.current = setTimeout(() => {
          handler.stop();
          audio.play();
        }, delayTime ?? 0);
      },

      stop() {
        if (audio.state() !== 'loaded') return;
        audio.stop();
      },

      playWithDelay(delayTime: number) {
        clearTimeout(delayTimeout.current);
        handler.play(delayTime);
      },
    };
  }, [audio]);

  useEffect(() => {
    const timer = delayTimeout.current;
    return () => {
      clearTimeout(timer);
      handler.stop();
    };
  }, [handler]);

  return handler;
}
