import { useMemo } from 'react';
import { Platform } from 'react-native';
import RNRestart from 'react-native-restart';

type RestartDevice = { restartDevice: () => void };

export const restartDevice = () => {
  if (Platform.OS == 'web') {
    window.location.reload();
  } else {
    RNRestart.Restart();
  }
};

export function useRestart(): RestartDevice {
  return useMemo(
    () => ({
      restartDevice: restartDevice,
    }),
    [],
  );
}
