const requestsQueue = [];

const sortRequests = () => {
  try {
    requestsQueue.sort((a, b) => {
      const aData = JSON.parse(a.data);
      const bData = JSON.parse(b.data);
      if (aData.priority !== undefined && bData.priority !== undefined) {
        return parseInt(aData.priority) - +bData.priority;
      }
    });
  } catch {
    null;
  }
};

const addToQueue = payload => {
  requestsQueue.push(payload);
  sortRequests();
};

const hasItemsInQueue = () => requestsQueue.length > 0;

const shiftQueue = () => requestsQueue.shift();

export { addToQueue, hasItemsInQueue, shiftQueue };
