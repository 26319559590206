import theme from '../common/theme';
import { INTERFACE_HEIGHT } from '../common/constants';
import { DocketDisplayItem, DocketDisplayItemType } from '../common/types';

export interface PaginatedDocketItems {
  page: number;
  columns: {
    column: number;
    items: DocketDisplayItem[];
  }[];
}

export const getPaginatedDocketItems = (
  items: DocketDisplayItem[],
  colWidth: number,
  scale: (n: number) => number,
  columnHeight: number,
): PaginatedDocketItems[] => {
  const { vw } = theme.window;
  const colHeight = columnHeight;
  const colsPerPage = Math.floor(vw / colWidth);
  let currentPage = 1;
  let currentColumn = 1;
  let currentHeight = 0;
  let currentColumnObject: { column: number; items: DocketDisplayItem[] } = {
    column: currentColumn,
    items: [],
  };

  const pages: PaginatedDocketItems[] = [
    { page: currentPage, columns: [currentColumnObject] },
  ];
  items.forEach((item, index) => {
    const needNewColumn =
      isOverflowingDocket(items, index, colHeight, currentHeight, scale) ||
      currentHeight + scale(item.height) > colHeight;
    // if current column is full or last item is not eligible to show in the current column, create new column
    if (needNewColumn) {
      currentColumn++;
      currentColumnObject = { column: currentColumn, items: [] };
      pages[currentPage - 1].columns.push(currentColumnObject);
      currentHeight = 0;
    }

    // if current page is full, create new page
    if (currentColumn - 1 >= colsPerPage) {
      currentPage++;
      currentColumn = 1;
      currentColumnObject = { column: currentColumn, items: [] };
      pages.push({ page: currentPage, columns: [currentColumnObject] });
      currentHeight = 0;
    }

    // add item to column
    currentColumnObject.items.push(item);
    currentHeight += scale(item.height);
  });

  return pages;
};

/**
 *
 * @returns if the current docket is overflowing the column or not
 */
const isOverflowingDocket = (
  items: DocketDisplayItem[],
  currentIndex: number,
  colHeight: number,
  currentHeight: number,
  scale: (n: number) => number,
): boolean => {
  const currentItem = items[currentIndex];

  if (currentItem.type === DocketDisplayItemType.DOCKET_HEADER) {
    const totalEligibleDocketHeight = getDocketHeightWithFirstItem(
      items,
      currentIndex,
    );
    if (scale(totalEligibleDocketHeight) + currentHeight > colHeight) {
      return true;
    }
  }
  return false;
};

/**
 *
 * @returns the total height of the docket including the first item
 */
const getDocketHeightWithFirstItem = (
  items: DocketDisplayItem[],
  currentIndex: number,
): number => {
  let height = items[currentIndex].height;
  for (let i = currentIndex + 1; i < items.length; i++) {
    height += items[i].height;
    if (items[i].type === DocketDisplayItemType.ITEM) {
      break;
    }
  }
  return height;
};
