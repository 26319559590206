import { ApolloError } from '@apollo/client';
import { OrderType } from '@oolio-group/domain';
import { useEffect, useMemo, useState } from 'react';
import { useLazyQuery } from '@apollo/client/react/hooks';
import { getItem, setItem } from '../storage/interface';
import { GET_ORDER_TYPES_QUERY } from '../graphql/orderType';
import { ORDER_TYPES_STORAGE_KEY } from '../common/constants';
import { noopHandler } from '../utils/general';

export interface UseOrderTypeProps {
  loading: boolean;
  error: string | undefined;
  orderTypes: OrderType[];
  getOrderTypes: () => void;
}

export function useOrderTypes(): UseOrderTypeProps {
  const [orderTypes, setOrderTypes] = useState<OrderType[]>([]);

  const getExistingLocalOrderTypes = async () => {
    const orderTypes =
      ((await getItem(ORDER_TYPES_STORAGE_KEY)) as OrderType[]) || [];
    setOrderTypes(orderTypes);
    return orderTypes;
  };

  const [getOrderTypes, getPrinterProfilesResponse] = useLazyQuery(
    GET_ORDER_TYPES_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: data => {
        if (data?.orderTypes) {
          setOrderTypes(data?.orderTypes);
          setItem(ORDER_TYPES_STORAGE_KEY, data.orderTypes);
        }
      },
      onError: noopHandler,
    },
  );

  useEffect(() => {
    const fetchOrderTypesIfNotCache = async () => {
      const ordTypes = await getExistingLocalOrderTypes();
      if (!ordTypes || !ordTypes.length) {
        getOrderTypes();
      }
    };
    fetchOrderTypesIfNotCache();
  }, [getOrderTypes]);

  const loading: boolean = getPrinterProfilesResponse.loading;

  const error: ApolloError | undefined = getPrinterProfilesResponse.error;

  return useMemo(
    () => ({
      loading,
      error: error ? (error as ApolloError)?.message : '',
      orderTypes,
      getOrderTypes,
    }),
    [loading, error, orderTypes, getOrderTypes],
  );
}
