import { useEffect, useMemo, useState } from 'react';
import { getSession } from '../state/preferences';
import { useKitchenDisplay } from './useKitchenDisplay';
import { useSession } from './useSession';

enum SessionValidState {
  'valid',
  'idle',
  'invalid',
  'nosession',
}

// Check session valid by making a request
// if there is no token or valid session: UNAUTHENTICATE error will throw
// if there is valid token: the request success and redirect user to cart navigator
// if there is expired token but a valid refresh token: the request success and redirect user to cart navigator
// if there is expired both token and refresh token: UNAUTHENTICATE error will throw
export const useInitialScreen = () => {
  const { setSession, session } = useSession();
  const { kitchenDisplay } = useKitchenDisplay({
    storeId: session.currentStore?.id,
    kitchenDisplayId: session.kitchenDisplay?.id,
  });
  const [isValidSession, setValidSession] = useState<SessionValidState>(
    SessionValidState.idle,
  );

  useEffect(() => {
    getSession().then(session => {
      if (session) {
        setSession(session);
        if (session.kitchenDisplay && session.token) {
          setValidSession(SessionValidState.valid);
        } else if (session.token) {
          setValidSession(SessionValidState.invalid);
        } else {
          setValidSession(SessionValidState.nosession);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const screen = useMemo(() => {
    if (kitchenDisplay) {
      setSession({ kitchenDisplay });
    }
    switch (isValidSession) {
      case SessionValidState.idle:
        return 'Idle';
      case SessionValidState.nosession:
        return 'LoginScreen';
      case SessionValidState.invalid:
        return 'AssignKds';

      default:
        return 'MainScreen';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidSession, kitchenDisplay]);

  return {
    screen,
  };
};
