/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useMemo } from 'react';
import { Text, View } from 'react-native';
import SortingOptionsStyles from './SortingOptions.styles';
import FiltersTab from '../FiltersTab/FiltersTab';
import { useSession } from '../../hooks/useSession';
import { SortingPreference } from '../../common/constants';
import { useOrderTypes } from '../../hooks/useOrderTypes';
import { useSalesChannels } from '../../hooks/useSalesChannels';
import { useTranslation } from '@oolio-group/localization';
import { useReactiveVar } from '@apollo/client';
import { docketsCategoriesCount } from '../../state/apolloVars';
import { SalesChannel } from '@oolio-group/domain';
import Sidebar from '../Sidebar/Sidebar';

interface SortingOptionsProps {
  onClose: () => void;
}

const SortingOptions: React.FC<SortingOptionsProps> = ({ onClose }) => {
  const styles = SortingOptionsStyles();
  const { session, setSession } = useSession();
  const { orderTypes } = useOrderTypes();
  const { salesChannels } = useSalesChannels();
  const { translate } = useTranslation();

  const onPressSort = useCallback(
    (sortingPreferenceLabel: string, sortingPreferenceValue: string) => {
      setSession({
        sortingPref: {
          label: sortingPreferenceLabel,
          value: sortingPreferenceValue,
        },
      });
    },
    [setSession],
  );

  const docketCategoriesCountMap: Record<string, number> = useReactiveVar(
    docketsCategoriesCount,
  );

  const sortedOrderTypes =
    orderTypes &&
    orderTypes.slice().sort((a, b) => {
      const countA = docketCategoriesCountMap[`orderType#${a.id}`] || 0;
      const countB = docketCategoriesCountMap[`orderType#${b.id}`] || 0;
      return countB - countA;
    });

  const sortedSalesChannel =
    salesChannels &&
    salesChannels.slice().sort((a: SalesChannel, b: SalesChannel) => {
      const countA = docketCategoriesCountMap[`salesChannel#${a.id}`] || 0;
      const countB = docketCategoriesCountMap[`salesChannel#${b.id}`] || 0;
      return countB - countA;
    });

  const salesChannelFilterTabs = useMemo(() => {
    return sortedSalesChannel.map(channel => (
      <FiltersTab
        testID="select-profile"
        title={channel.name}
        key={channel.code}
        onPress={() => {
          onPressSort(channel.name, channel.id);
        }}
        docketsCount={docketCategoriesCountMap[`salesChannel#${channel.id}`]}
        checked={session.sortingPref?.value === channel.id}
      />
    ));
  }, [
    docketCategoriesCountMap,
    onPressSort,
    session.sortingPref?.value,
    sortedSalesChannel,
  ]);

  const orderTypesFilterTabs = useMemo(() => {
    return sortedOrderTypes.map(type => (
      <FiltersTab
        testID="select-profile"
        title={type.name}
        key={type.code}
        onPress={() => {
          onPressSort(type.name, type.id);
        }}
        docketsCount={docketCategoriesCountMap[`orderType#${type.id}`]}
        checked={session.sortingPref?.value === type.id}
      />
    ));
  }, [
    docketCategoriesCountMap,
    onPressSort,
    session.sortingPref?.value,
    sortedOrderTypes,
  ]);

  const resetButton = useMemo(() => {
    return (
      <Text style={styles.resetText}>
        {translate('pricings.resetActionTitle')}
      </Text>
    );
  }, [styles, translate]);

  return (
    <Sidebar
      onClose={onClose}
      title={translate('kitchenDisplay.sortDockets')}
      buttonRight={resetButton}
      onPressRight={() =>
        onPressSort('Oldest First', SortingPreference.OLDEST_FIRST)
      }
    >
      <View style={{ width: '100%' }}>
        <Text style={styles.subTitleText}>{translate('settings.general')}</Text>
        <FiltersTab
          testID="select-profile"
          title={translate('kitchenDisplay.sortOldest')}
          onPress={() => {
            onPressSort('Oldest First', SortingPreference.OLDEST_FIRST);
          }}
          checked={
            !session.sortingPref ||
            session.sortingPref.value === SortingPreference.OLDEST_FIRST
          }
        />
        <FiltersTab
          testID="select-profile"
          title={translate('kitchenDisplay.sortLatest')}
          onPress={() => {
            onPressSort('Latest First', SortingPreference.NEWEST_FIRST);
          }}
          checked={
            session.sortingPref?.value === SortingPreference.NEWEST_FIRST
          }
        />
        <FiltersTab
          testID="select-profile"
          title={translate('kitchenDisplay.sortTableName')}
          onPress={() => {
            onPressSort('Table Name', 'TABLE_NAME');
          }}
          checked={session.sortingPref?.value === 'TABLE_NAME'}
        />
        <Text style={styles.subTitleText}>
          {translate('kitchenDisplay.sortOrderType')}
        </Text>
        {orderTypesFilterTabs}
        <Text style={styles.subTitleText}>
          {translate('kitchenDisplay.sortSalesChannel')}
        </Text>
        {salesChannelFilterTabs}
      </View>
    </Sidebar>
  );
};

export default SortingOptions;
