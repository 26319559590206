import { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { useColors } from '../../hooks/useColors';
import theme from '../../common/theme';
import { useScale } from '../../hooks/useScale';

const DocketItemStyles = () => {
  const { scale } = useScale();
  const { colors } = useColors();

  return useMemo(
    () =>
      StyleSheet.create({
        // Docket Header
        header: {
          paddingHorizontal: scale(14),
          paddingVertical: scale(12),
          marginVertical: scale(4),
          borderRadius: theme.radius.m,
        },
        trainingHeader: {
          paddingVertical: scale(8),
          borderTopLeftRadius: theme.radius.m,
          borderTopRightRadius: theme.radius.m,
          backgroundColor: colors.red,
          alignItems: 'center',
          justifyContent: 'center',
        },
        headerSpacingForTraining: {
          marginBottom: scale(4),
          marginTop: scale(0),
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        },
        headerPrintEnabled: {
          paddingStart: scale(14),
          marginVertical: scale(4),
          borderRadius: theme.radius.m,
        },
        headerContent: {
          flexDirection: 'row',
          justifyContent: 'space-between',
        },
        rowGroup: {
          flex: 1,
          alignSelf: 'center',
        },
        row: {
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        },
        printIconContainer: {
          paddingVertical: scale(24),
          paddingHorizontal: scale(14),
          marginStart: scale(12),
          backgroundColor: colors.transparent20,
        },
        text: {
          color: colors.white,
          letterSpacing: -0.4,
          fontSize: scale(16),
          lineHeight: scale(22),
          fontFamily: theme.fonts.medium,
          fontVariant: ['tabular-nums'],
        },
        trainingText: {
          fontSize: scale(16),
        },
        // Product
        product: {
          padding: scale(6),
          marginBottom: scale(4),
          borderRadius: theme.radius.s,
        },
        productHighlighted: {
          backgroundColor: colors.text,
          borderRadius: theme.radius.s,
        },
        line: {
          flexDirection: 'row',
        },
        name: {
          flex: 1,
          color: colors.text,
          letterSpacing: -0.2,
          fontSize: scale(16),
          lineHeight: scale(20),
          paddingVertical: scale(6),
          fontFamily: theme.fonts.medium,
        },
        nameHighlighted: {
          flex: 1,
          color: colors.textInverted,
          letterSpacing: -0.2,
          fontSize: scale(16),
          lineHeight: scale(20),
          paddingVertical: scale(6),
          fontFamily: theme.fonts.medium,
        },
        qty: {
          height: scale(32),
          minWidth: scale(30),
          marginRight: scale(8),
          paddingHorizontal: scale(4),
          borderRadius: theme.radius.s,
          backgroundColor: colors.qtyView,
        },
        qtyHighlighted: {
          backgroundColor: colors.qtyViewInverted,
        },
        qtyText: {
          fontSize: scale(14),
          lineHeight: scale(14),
          color: colors.text,
          textAlign: 'center',
          fontVariant: ['tabular-nums'],
          fontFamily: theme.fonts.medium,
        },
        modifier: {
          opacity: 0.6,
          fontSize: scale(16),
          marginTop: scale(4),
          lineHeight: scale(20),
          marginLeft: scale(38),
          color: colors.text,
          letterSpacing: -0.2,
          fontFamily: theme.fonts.medium,
        },
        modifierHighlighted: {
          color: colors.textInverted,
        },
        note: {
          letterSpacing: -0.2,
          fontSize: scale(16),
          lineHeight: scale(20),
          marginLeft: scale(38),
          marginBottom: scale(4),
          color: colors.noteText,
        },
        // Icon
        icon: {
          width: scale(40),
          height: scale(36),
          marginLeft: 'auto',
          alignItems: 'center',
          justifyContent: 'center',
        },
        // Recalled, Due & Voided
        statusContainer: {
          marginVertical: scale(4),
          paddingVertical: scale(8),
          paddingHorizontal: scale(12),
          borderRadius: theme.radius.s,
        },
        statusText: {
          fontSize: scale(14),
          textAlign: 'center',
          paddingVertical: scale(4),
          fontFamily: theme.fonts.medium,
        },
        statusTextUppercase: {
          fontSize: scale(14),
          textAlign: 'center',
          paddingVertical: scale(4),
          fontFamily: theme.fonts.medium,
          textTransform: 'uppercase',
        },
        // Item Specific
        // View Styles
        dueContainer: {
          backgroundColor: colors.dueView,
        },
        noteContainer: {
          backgroundColor: colors.amber,
        },
        recalledContainer: {
          backgroundColor: colors.recalledView,
        },
        voidedContainer: {
          backgroundColor: colors.voidedView,
        },
        transferredContainer: {
          backgroundColor: colors.transferredView,
        },
        courseContainer: {
          backgroundColor: colors.courseView,
        },
        // Text Styles
        preppedItem: {
          color: colors.grey6,
        },
        completedItem: {
          color: colors.completedText,
          textDecorationLine: 'line-through',
        },
        servedItem: {
          color: colors.green,
          textDecorationLine: 'line-through',
        },
        transferredItem: {
          color: colors.grey6,
          textDecorationLine: 'line-through',
        },
        voidedItem: {
          color: colors.red,
          textDecorationLine: 'line-through',
        },
        // Text Colours
        dueText: {
          color: colors.dueText,
        },
        courseText: {
          color: colors.courseText,
        },
        recallText: {
          color: colors.text,
        },
        recalledText: {
          color: colors.recalledText,
        },
        transferredText: {
          color: colors.transferredText,
        },
        voidedText: {
          color: colors.voidedText,
        },
      }),
    [colors, scale],
  );
};

export default DocketItemStyles;
