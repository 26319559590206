import { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { useScale } from '../../hooks/useScale';
import { useColors } from '../../hooks/useColors';

const NotificationStyles = () => {
  const { scale } = useScale();
  const { colors } = useColors();

  return useMemo(
    () =>
      StyleSheet.create({
        errorContainer: {
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          paddingVertical: scale(16),
          paddingHorizontal: scale(20),
          backgroundColor: colors.notificationContainer,
          zIndex: 998,
        },
        errorText: {
          fontSize: scale(14),
          color: colors.notificationText,
        },
      }),
    [scale, colors],
  );
};

export default NotificationStyles;
