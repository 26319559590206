import {
  AuthorizationServiceConfiguration,
  BaseTokenRequestHandler,
  FetchRequestor,
  GRANT_TYPE_REFRESH_TOKEN,
  TokenRequest,
  TokenResponse,
} from '@openid/appauth';
import jwt_decode from 'jwt-decode';
import config from '../config';
import { tokenState, tokenUpdated } from '../state/apolloVars';
import * as settings from '../state/preferences';

export const refetchTokenSerially = async () => {
  const session = await settings.getSession();
  const tokenRequest = new TokenRequest({
    client_id: config.auth0.clientId,
    redirect_uri: config.auth0.redirectUrl,
    grant_type: GRANT_TYPE_REFRESH_TOKEN,
    refresh_token: session?.refreshToken,
  });
  const issuerConfig = await AuthorizationServiceConfiguration.fetchFromIssuer(
    config['auth0'].issuer || '',
    new FetchRequestor(),
  );
  const response = (await new BaseTokenRequestHandler(
    new FetchRequestor(),
  ).performTokenRequest(
    issuerConfig,
    tokenRequest,
  )) as unknown as Promise<Response>;
  const { accessToken, refreshToken } = response as unknown as TokenResponse;
  const accessTokenPayload = jwt_decode<{
    exp: number;
  }>(accessToken as string);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const newTokenPayload: any = {
    token: accessToken as string,
    refreshToken: refreshToken as string,
    expiredDate: accessTokenPayload.exp * 1000,
  };
  tokenState(newTokenPayload);
  await settings.setSession({
    ...session,
    ...newTokenPayload,
  });
  tokenUpdated({ timestamp: Date.now() });
  return { accessToken };
};
