import React, {
  createContext,
  useState,
  useCallback,
  useContext,
  useMemo,
  useEffect,
} from 'react';
import { themes, themeType } from '../common/themeColors';
import * as preferences from '../state/preferences';
import { Theme } from '../common/types';

export interface ThemeProviderProps {
  children: React.ReactNode;
}

export interface ThemeContextValue {
  colors: themeType;
  changeTheme: (newTheme: Theme) => void;
  activeTheme: Theme;
}

export const ThemeContext = createContext<ThemeContextValue>(
  {} as ThemeContextValue,
);

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const [activeTheme, setActiveTheme] = useState<Theme>(Theme.LIGHT);

  const changeTheme = useCallback(async (newTheme: Theme) => {
    setActiveTheme(newTheme);
    await preferences.setTheme(newTheme);
  }, []);

  const getTheme = async () => {
    return (await preferences.getTheme()) as Theme;
  };

  useEffect(() => {
    (async () => {
      const theme = await getTheme();
      setActiveTheme(theme);
    })();
  }, []);

  const value = useMemo(
    () => ({
      colors: themes[activeTheme],
      changeTheme,
      activeTheme,
    }),
    [changeTheme, activeTheme],
  );

  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};

export function useColors(): ThemeContextValue {
  return useContext(ThemeContext);
}
