import { GraphQLRequest } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import jwt_decode from 'jwt-decode';
import { App } from '@oolio-group/domain';
import * as settings from '../state/preferences';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import {
  AuthorizationServiceConfiguration,
  BaseTokenRequestHandler,
  FetchRequestor,
  GRANT_TYPE_REFRESH_TOKEN,
  TokenRequest,
  TokenResponse,
} from '@openid/appauth';
import { tokenState } from '../state/apolloVars';
import config from '../config';
import { noopHandler } from './errorHandlers';
import {
  POSTHOG_EVENT_ACTIONS,
  POSTHOG_EVENT_DATA,
  analyticsService,
} from '../analytics/AnalyticsService';

interface AuthHeader {
  authorization?: string;
  organization?: string;
  venue?: string;
  store?: string;
  device?: string;
  appToken?: string;
  kitchenDisplay?: string;
  app?: App;
}

export const tokenLookup = async (): Promise<AuthHeader> => {
  const session = await settings.getSession();
  return {
    organization: session?.currentOrganization?.id || '',
    venue: session?.currentVenue?.id || '',
    store: session?.currentStore?.id || '',
    authorization: session?.token || '',
    device: session?.device?.id || '',
    kitchenDisplay: session?.kitchenDisplay?.id || '',
    app: session?.activeApp,
  };
};

const isLoginMutation = (request: GraphQLRequest) =>
  request.operationName === 'kdsLoginByDeviceCode';

/* istanbul ignore file */
export const withToken = setContext((request, { headers }) => {
  return isLoginMutation(request)
    ? headers
    : tokenLookup().then(tokenHeaders => ({
        headers: {
          ...tokenHeaders,
          ...headers,
        },
      }));
});

/**
 * This will only validate or handle refresh token related errors
 */
export const refreshSession = new TokenRefreshLink({
  isTokenValidOrUndefined: async () => {
    const session = await settings.getSession();
    if (
      session?.token &&
      session?.expiredDate &&
      (session?.expiredDate as number) > Date.now()
    ) {
      return true;
    }
    return false;
  },
  fetchAccessToken: async () => {
    const session = await settings.getSession();
    const tokenRequest = new TokenRequest({
      client_id: config.auth0.clientId,
      redirect_uri: config.auth0.redirectUrl,
      grant_type: GRANT_TYPE_REFRESH_TOKEN,
      refresh_token: session?.refreshToken,
    });
    const issuerConfig =
      await AuthorizationServiceConfiguration.fetchFromIssuer(
        config['auth0'].issuer || '',
        new FetchRequestor(),
      );
    return new BaseTokenRequestHandler(
      new FetchRequestor(),
    ).performTokenRequest(
      issuerConfig,
      tokenRequest,
    ) as unknown as Promise<Response>;
  },
  handleResponse: () => async (response: Response) => {
    const { accessToken, refreshToken } = response as unknown as TokenResponse;
    const oldSession = await settings.getSession();

    const accessTokenPayload = jwt_decode<{
      exp: number;
    }>(accessToken as string);

    // update new access token
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newTokenPayload: any = {
      token: accessToken as string,
      refreshToken: refreshToken as string,
      expiredDate: accessTokenPayload.exp * 1000,
    };
    tokenState(newTokenPayload);
    await settings.setSession({
      ...oldSession,
      ...newTokenPayload,
    });
    const session = await settings.getSession();
    analyticsService.capture(POSTHOG_EVENT_ACTIONS.TOKEN_REFRESH_OR_EXPIRED, {
      orgId: session?.currentOrganization?.id,
      orgName: session?.currentOrganization?.name,
    } as POSTHOG_EVENT_DATA);
    return {
      access_token: accessToken,
    };
  },
  handleFetch: noopHandler,
  handleError: async error => {
    // logout user
    console.error('FAIL TO REFRESH USER TOKEN', error);
    tokenState({});
    await clearSession();
  },
});

export const clearSession = async () => {
  await settings.setSession({ authorized: false });
};

export const authFlowLink = [refreshSession, withToken];
