import React, { FC } from 'react';
import { TouchableOpacity, ActivityIndicator, ViewStyle } from 'react-native';
import { useColors } from '../../hooks/useColors';
import ButtonStyles from './Buttons.styles';
import Icon from '../Icon/Icon';

interface ButtonIconProps {
  testID: string;
  icon: string;
  loading?: boolean;
  disabled?: boolean;
  onPress: () => void;
  containerStyle?: ViewStyle | ViewStyle[];
  type?:
    | 'positive'
    | 'negative'
    | 'neutralLight'
    | 'cancel'
    | 'interface'
    | 'active';
  ignoreTheme?: boolean;
}

const ButtonIcon: FC<ButtonIconProps> = ({
  testID,
  icon,
  onPress,
  loading = false,
  disabled = false,
  containerStyle,
  type,
  ignoreTheme = false,
}) => {
  const styles = ButtonStyles();
  const { colors } = useColors();
  const foregroundColor = ignoreTheme ? colors.white : colors.text;

  const getContainerStyle = (type?: string) => {
    const style: ViewStyle[] = [styles.container];

    switch (type) {
      case 'positive':
        style.push({ backgroundColor: colors.green });
        break;
      case 'negative':
        style.push({ backgroundColor: colors.red });
        break;
      case 'neutralLight':
        style.push({ backgroundColor: colors.blueLight });
        break;
      case 'interface':
        style.push({
          backgroundColor: ignoreTheme ? colors.tertiary3 : colors.text,
        });
        break;
      case 'active':
        style.push({ backgroundColor: foregroundColor });
        break;
      case 'cancel':
        style.push({ backgroundColor: colors.grey1 });
        break;
      case 'disabled':
        style.push({ opacity: 0.3 });
        break;
      default:
        return style;
    }

    return style;
  };

  return (
    <TouchableOpacity
      testID={testID}
      onPress={onPress}
      disabled={disabled || loading}
      style={[getContainerStyle(disabled ? 'disabled' : type), containerStyle]}
    >
      {!loading ? (
        <Icon
          name={icon}
          size={20}
          color={
            type === 'neutralLight'
              ? colors.blue
              : type === 'cancel'
              ? colors.black1
              : type === 'active'
              ? colors.tertiary3
              : ignoreTheme
              ? colors.white
              : colors.text
          }
        />
      ) : (
        <ActivityIndicator color={foregroundColor} />
      )}
    </TouchableOpacity>
  );
};

export default ButtonIcon;
