import { Docket, DocketItemStatus, PrintingOptions } from '@oolio-group/domain';
import { WorkerAction, WorkerActionResultStatus } from '../../workers/utils';
import { useTranslation } from '@oolio-group/localization';
import { useSession } from '../useSession';
import { useCallback } from 'react';

import { printKitchenDocketHandler } from '../../workers/handlers/printKitchenDocketHandler';
import { workerInstanceVar } from '../../state/apolloVars';
import printHandler from '../../workers/handlers/printHandler';
import { Notification } from '../useNotification';
import { isEmptyOrNull } from '../../utils/string';

export interface PrintError {
  message?: string;
}
export interface UsePrintingWithTemplate {
  printKitchenDocket: (
    docket: Docket,
    printer: PrintingOptions,
  ) => Promise<Notification | undefined>;
}

export function usePrintingWithTemplate(): UsePrintingWithTemplate {
  const { translate } = useTranslation();
  const { session } = useSession();

  const workerInstance = workerInstanceVar();

  const printKitchenDocket = useCallback(
    async (
      docket: Docket,
      printer: PrintingOptions,
    ): Promise<Notification | undefined> => {
      if (docket?.itemStatus === DocketItemStatus.VOIDED) {
        return {
          error: true,
          message: translate('kitchenDisplay.voidDocketPrintError'),
        };
      }
      const docketItems = docket.docketItems;
      const orderName = !isEmptyOrNull(docket.customer.name)
        ? docket.customer.name
        : !isEmptyOrNull(docket.table?.name)
        ? docket.table?.name
        : docket?.orderNumber;
      const errorMessage = translate('kitchenDisplay.printingFailure', {
        orderName: orderName,
      });
      const bufferPayload = {
        action: WorkerAction.PRINT_KITCHEN_DOCKET,
        data: {
          docket,
          session,
          docketItems,
          translatedNowCourse: translate('common.now'),
          printingOptions: printer,
        },
      };

      let bufferObjs;

      try {
        bufferObjs = printKitchenDocketHandler(bufferPayload);
      } catch (error) {
        return {
          error: true,
          message: errorMessage,
        };
      }

      if (!bufferObjs.length) {
        return {
          error: true,
          message: errorMessage,
        };
      }
      const workerPayload = {
        action: WorkerAction.PRINT_KITCHEN_DOCKET,
        data: {
          bufferObjs,
          docket,
          errorMessage: errorMessage,
        },
        priority: 1,
      };
      if (workerInstance) {
        workerInstance.send(workerPayload);
      } else {
        const results = await printHandler(workerPayload);
        if (
          results.some(
            result => result.status === WorkerActionResultStatus.ERROR,
          )
        ) {
          return {
            error: true,
            message: errorMessage,
          };
        }
      }
    },
    [session, translate, workerInstance],
  );

  return {
    printKitchenDocket,
  };
}
