import { Docket } from '@oolio-group/domain';
import { isOnlineOrder } from './generateOnlineOrderDetail';

export const getOrderChannel = (docket: Docket): {} => {
  if (!isOnlineOrder(docket)) {
    // if non online order we do not show channel as it is always IN STORE
    return '';
  }

  return `${docket?.salesChannel?.name || ''} (${
    docket.integrationInfo?.channelOrderDisplayId || docket.orderNumber
  })`;
};
