import React from 'react';
import { View, ActivityIndicator } from 'react-native';
import { DocketItemStatus, DocketStatus } from '@oolio-group/domain';
import { useColors } from '../../../hooks/useColors';
import Icon from '../../Icon/Icon';
import DocketItemStyles from '../DocketItem.styles';

export interface DocketDisplayItemIconProps {
  itemStatus: DocketItemStatus;
  status: DocketStatus;
  bumpItemLoading?: boolean;
}

export interface StatusIconProps {
  itemStatus: DocketItemStatus;
  status: DocketStatus;
}

const StatusIcon: React.FC<StatusIconProps> = ({ itemStatus, status }) => {
  const { colors } = useColors();

  switch (itemStatus) {
    case DocketItemStatus.VOIDED:
      return <Icon size={24} name="exclamation-circle" color={colors.red} />;
    case DocketItemStatus.REBUMPED:
      return (
        <>
          {status == DocketStatus.CREATED ? (
            <Icon size={24} name="check" color={colors.amber} />
          ) : (
            <Icon
              size={20}
              name="redo"
              color={colors.text}
              style={{ transform: [{ scaleX: -1 }] }}
            />
          )}
        </>
      );
    case DocketItemStatus.BUMPED:
      return (
        <>
          {status == DocketStatus.CREATED ? (
            <Icon size={24} name="check" color={colors.grey6} />
          ) : (
            <Icon
              size={20}
              name="redo"
              color={colors.text}
              style={{ transform: [{ scaleX: -1 }] }}
            />
          )}
        </>
      );
    case DocketItemStatus.SERVED:
      return (
        <>
          {status == DocketStatus.CREATED ? (
            <Icon size={24} name="check-circle" color={colors.green} />
          ) : (
            <Icon
              size={20}
              name="redo"
              color={colors.text}
              style={{ transform: [{ scaleX: -1 }] }}
            />
          )}
        </>
      );
    default:
      null;
  }
  return null;
};

const DocketDisplayItemIcon: React.FC<DocketDisplayItemIconProps> = ({
  status,
  itemStatus,
  bumpItemLoading,
}) => {
  const { colors } = useColors();
  const styles = DocketItemStyles();

  return (
    <View testID="item-icon" style={styles.icon}>
      {bumpItemLoading ? (
        <ActivityIndicator size="small" color={colors.text} />
      ) : (
        <StatusIcon itemStatus={itemStatus} status={status} />
      )}
    </View>
  );
};

export default DocketDisplayItemIcon;
