import LogRocket from '@logrocket/react-native';
import * as sessionPreferences from '../state/preferences';

export const buildUserInfo = (session: sessionPreferences.Session) => ({
  name: session.currentOrganization?.name || 'NA',
  userName: session.kitchenDisplay?.name || 'NA',
  email: 'NA',
  organization: session.currentOrganization?.id || 'NA',
});

export const identifyLogRocketUser = (session: sessionPreferences.Session) => {
  LogRocket.identify(
    session.kitchenDisplay?.id || 'NA',
    buildUserInfo(session),
  );
};
