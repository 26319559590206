import { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { useColors } from '../../hooks/useColors';
import { useScale } from '../../hooks/useScale';
import theme from '../../common/theme';

const AllDayViewStyles = () => {
  const { colors } = useColors();
  const { scale } = useScale();

  return useMemo(
    () =>
      StyleSheet.create({
        item: {
          minHeight: scale(44),
          width: scale(280),
          padding: scale(10),
          borderColor: colors.borderColor,
          borderBottomWidth: 1,
        },
        itemSelected: {
          backgroundColor: colors.text,
          borderRadius: theme.radius.s,
        },
        innerRow: {
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        },
        nameContainer: {
          flex: 1,
          flexDirection: 'row',
          alignItems: 'center',
        },
        qtyContainer: {
          width: scale(60),
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-end',
        },
        itemText: {
          color: colors.text,
          fontSize: scale(14),
          fontFamily: theme.fonts.semibold,
        },
        itemSelectedText: {
          color: colors.textInverted,
        },
        optionText: {
          color: colors.grey5,
          fontSize: scale(12),
          paddingTop: scale(6),
        },
        courseText: {
          color: colors.orange,
          fontSize: scale(12),
          paddingRight: scale(6),
        },
        action: {
          flexDirection: 'row',
          height: scale(60),
        },
        btn: {
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
        },
        btnText: {
          color: colors.white,
          fontFamily: theme.fonts.bold,
          fontSize: scale(14),
        },
      }),
    [colors, scale],
  );
};

export default AllDayViewStyles;
