import React, { useEffect } from 'react';
import { analyticsService } from './AnalyticsService';

interface AnalyticsInitializerProps {
  children: React.ReactNode;
}

const AnalyticsInitilizer: React.FC<AnalyticsInitializerProps> = ({
  children,
}) => {
  useEffect(() => {
    analyticsService.init();
  }, []);

  return <>{children}</>;
};

export default AnalyticsInitilizer;
