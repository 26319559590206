import { WorkerInput } from './utils';
import workerHandlers from './handlers/workerHandlers';

export default async function (message: WorkerInput) {
  const actionHandler = workerHandlers[message.action];
  if (!actionHandler) {
    return;
  } else {
    return await actionHandler(message);
  }
}
