import React, { useMemo, useState } from 'react';
import {
  View,
  Text,
  ActivityIndicator,
  TouchableOpacity,
  ScrollView,
} from 'react-native';
import { KitchenDisplay } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import theme from '../../common/theme';
import Icon from '../../component/Icon/Icon';
import Search from '../../component/Search/Search';
import styles from './DeviceSelection.styles';

interface DeviceListProps {
  onSelect?: (device: KitchenDisplay) => void;
  loading: boolean;
  devices: KitchenDisplay[];
}
const DeviceList: React.FC<DeviceListProps> = ({
  loading,
  devices,
  onSelect = () => undefined,
}: DeviceListProps) => {
  const { translate } = useTranslation();
  const [searchText, onSearch] = useState('');

  const filteredDevices = useMemo(() => {
    return devices.filter(device =>
      device.name.toLowerCase().includes(searchText.toLowerCase()),
    );
  }, [searchText, devices]);

  return (
    <View style={styles.content}>
      <Text style={styles.title}>
        {translate('assignRegister.selectDevice')}
      </Text>
      <Search
        testID="search-devices"
        maxLength={50}
        onChangeText={onSearch}
        placeholder={translate('assignRegister.searchDevice')}
        containerStyle={styles.search}
      />
      {loading ? (
        <View style={styles.loading}>
          <ActivityIndicator size={20} color={theme.colors.primary} />
        </View>
      ) : (
        <View style={styles.table}>
          <ScrollView>
            {filteredDevices.length > 0 ? (
              filteredDevices.map(device => (
                <View key={device.id} testID="row-device" style={styles.group}>
                  <TouchableOpacity
                    style={styles.rowParent}
                    onPress={() => onSelect(device)}
                  >
                    <View style={styles.deviceText}>
                      <Text style={styles.textParent}>{device.name}</Text>
                      <Text
                        style={[
                          styles.deviceInfoText,
                          {
                            color: !device.isPaired
                              ? theme.colors.red
                              : theme.colors.grey6,
                          },
                        ]}
                      >
                        {device.isPaired
                          ? device.details
                          : translate('assignRegister.deviceNotPaired')}
                      </Text>
                    </View>
                    <Icon
                      name="UilAngleRight"
                      size={20}
                      color={theme.colors.black1}
                    />
                  </TouchableOpacity>
                </View>
              ))
            ) : (
              <View style={styles.loading}>
                <Text style={styles.emptyText}>
                  {translate('DeviceSelection.noDevices')}
                </Text>
              </View>
            )}
          </ScrollView>
        </View>
      )}
    </View>
  );
};

export default DeviceList;
