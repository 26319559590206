import React from 'react';
import { View, Text } from 'react-native';
import { DocketStatus } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { getDueAtTime } from '../../../utils/timeHelper';
import DocketItemStyles from '../DocketItem.styles';

export interface DocketDueTimeProps {
  name: string;
  status: DocketStatus;
}

const DocketDueTime: React.FC<DocketDueTimeProps> = ({ name, status }) => {
  const styles = DocketItemStyles();
  const { translate } = useTranslation();
  const timeText =
    status == DocketStatus.CREATED ? getDueAtTime(Number(name)) : '';

  return (
    <View
      testID="docket-due"
      style={[styles.statusContainer, styles.dueContainer]}
    >
      <Text style={[styles.statusTextUppercase, styles.dueText]}>
        {`${translate('kitchenDisplay.dueAt')} ${timeText}`}
      </Text>
    </View>
  );
};

export default React.memo(DocketDueTime);
