import * as storage from '../storage/interface';

export const LOCALE_KEY = 'preferredLocale';
export const SESSION_KEY = 'session';
export const SCALE_KEY = 'scale';
export const THEME_KEY = 'preferredTheme';
import {
  App,
  Device,
  KitchenDisplay,
  Organization,
  Store,
  Venue,
  Section,
} from '@oolio-group/domain';
import { Scale, Theme } from '../common/types';

interface SortingPreference {
  label: string;
  value: string;
}

export interface Session {
  authorized: boolean;
  token?: string;
  refreshToken?: string;
  expiredDate?: number;
  currentVenue?: Partial<Venue>;
  currentStore?: Partial<Store>;
  currentOrganization?: Partial<Organization>;
  kitchenDisplay?: Partial<KitchenDisplay>;
  device?: Partial<Device>;
  activeApp?: App;
  audioToggle?: boolean;
  localeCode?: string;
  rebumpToggle?: boolean;
  sortingPref?: SortingPreference;
  sections?: Partial<Section>[];
}

export async function getSession(): Promise<Session | undefined> {
  try {
    const session = await storage.getItem<Session>(SESSION_KEY);
    return session || { authorized: false };
  } catch (e) {}

  return undefined;
}

export async function setSession(data: Session) {
  try {
    storage.setItem(SESSION_KEY, data);
  } catch (e) {}
}

export async function getScale(): Promise<Scale | undefined> {
  try {
    const session = await storage.getItem<Scale>(SCALE_KEY);
    return session || Scale.MEDIUM;
  } catch (e) {}

  return undefined;
}

export async function setScale(data: Scale) {
  try {
    storage.setItem(SCALE_KEY, data);
  } catch (e) {}
}

export async function getTheme(): Promise<Theme | undefined> {
  try {
    const session = await storage.getItem<Theme>(THEME_KEY);
    return session || Theme.LIGHT;
  } catch (e) {}

  return undefined;
}

export async function setTheme(data: Theme) {
  try {
    storage.setItem(THEME_KEY, data);
  } catch (e) {}
}
