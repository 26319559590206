import { Printer, PrintingOptions } from '@oolio-group/domain';

export const generatePrinterOptions = (
  printer: Printer,
  singleItemPrinting: boolean,
): PrintingOptions => {
  return {
    profile: {
      name: 'Kitchen',
      locale: null,
      orderTypes: [],
      singleItemPrinting: singleItemPrinting,
      __typename: 'PrinterProfile',
      id: '',
    },
    printer: printer,
    printerProfileType: 'KITCHEN',
    template: {
      id: '',
      template:
        '<?xml version="1.0" encoding="UTF-8"?>\n    <document>\n        <align mode="center">\n    \n            <!-- Organization logo -->\n            <line-feed />\n            <!--<image density="d24">\n                {{{orgLogo}}}\n                </image> -->\n    \n            <!-- store name -->\n    \n            <align mode="center">\n                <bold>\n                    <text-line size="1:0">{{storeName}}</text-line>\n                </bold>\n            </align>\n            <line-feed />\n    \n            <!-- Store details -->\n            <align mode="center">\n                <small>\n                    <text-line>{{storeNo}}</text-line>\n                    <text-line>{{storeAddress}}</text-line>\n                    <text-line>{{storePhone}}</text-line>\n                </small>\n            </align>\n            <line-feed />\n            <align mode="center">\n    \n                <text-line>{{{divider}}}</text-line>\n            </align>\n            <line-feed />\n    \n            <!-- Order details -->\n            <align mode="center">\n                <text-line size="0:0">{{orderDetails}}</text-line>\n            </align>\n    \n            <line-feed />\n            <align mode="center">\n    \n                <text-line>{{{divider}}}</text-line>\n            </align>\n            <line-feed />\n    \n            <!-- Order items -->\n            <align mode="center">\n                <text-line size="0:0">{{orderItems}}</text-line>\n            </align>\n    \n            <line-feed />\n            <align mode="center">\n    \n                <text-line>{{{divider}}}</text-line>\n            </align>\n            <line-feed />\n    \n        </align>\n        <line-feed />\n       </document>',
      isDeleted: false,
      name: 'Kitchen Template',
      templateType: 'KITCHEN',
      isBaseTemplate: true,
      headerText: null,
      footerText: null,
      logoURL: null,
      socialLinks: null,
      oolioBranding: null,
      __typename: 'PrinterTemplate',
    },
    __typename: 'PrintingOptions',
  } as unknown as PrintingOptions;
};
