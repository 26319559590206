import moment from 'moment';

export const getTimeDifference = (start: number, end: number) => {
  const duration = moment.duration(moment(start).diff(end));

  if (!duration.isValid()) return;

  const hour = Math.floor(duration.asMinutes() / 60);
  const mins = duration.minutes().toString().padStart(2, '0');
  const secs = duration.seconds().toString().padStart(2, '0');

  return `${hour ? hour + ':' : ''}${mins}:${secs}`;
};

export const convertToDoubleDigit = (input: string): string => {
  if (input.length == 1) {
    return `0${input}`;
  }
  return input;
};

export const getDueAtTime = (start: number) => {
  const currentTime = Date.now();
  const duration = moment.duration(moment(start).diff(currentTime));
  const hoursDiff = Math.floor(duration.asMinutes() / 60);
  const time = moment(start).format('hh:mm A');
  const date = moment(start).format('DD-MM-YYYY');
  if (hoursDiff > 24) {
    // when time diff is greater than 24 hours we have to show
    // DUE AT 16-11-2022 01:15 PM
    return `${date} ${time}`;
  } else {
    // when time diff is less than 24 hours we have to show
    // DUE AT 01:15 PM
    return `${time}`;
  }
};

export const minifyMinuteText = (text: string) => {
  return text.replace('minute', 'min');
};
export const minifyHourText = (text: string) => {
  return text.replace('hour', 'hr');
};

export const getStartTimeandEndTime = (startTime: string) => {
  const dateTimeSplited = startTime?.split(':');
  const start = moment().set({
    hour: Number(dateTimeSplited?.[0] || 0),
    minute: Number(dateTimeSplited?.[1] || 0),
  });
  const end = moment()
    .set({
      hour: Number(dateTimeSplited?.[0] || 0),
      minute: Number(dateTimeSplited?.[1] || 0),
    })
    .add(60 * 24 - 1, 'minutes');

  return {
    startTime: start.valueOf(),
    endTime: end.valueOf(),
  };
};
