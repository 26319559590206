import React from 'react';
import { TouchableOpacity, Text } from 'react-native';
import { Notification } from '../../hooks/useNotification';
import NotificationStyles from './Notification.styles';

export interface NotificationProps {
  notification: Notification;
  closeNotification: (notification: Notification) => void;
}

export const NotificationBanner = (props: NotificationProps) => {
  const styles = NotificationStyles();
  return (
    <TouchableOpacity
      style={styles.errorContainer}
      onPress={() => props.closeNotification(props.notification)}
      disabled={false}
    >
      <Text style={styles.errorText}>{props.notification.message}</Text>
    </TouchableOpacity>
  );
};
