import { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { useColors } from '../../hooks/useColors';
import { useScale } from '../../hooks/useScale';
import theme from '../../common/theme';

const FiltersTabStyles = () => {
  const { colors } = useColors();
  const { scale } = useScale();

  return useMemo(
    () =>
      StyleSheet.create({
        container: {
          height: scale(40),
          flexDirection: 'row',
          alignItems: 'center',
          backgroundColor: colors.popupBg,
          paddingHorizontal: scale(14),
          borderBottomColor: colors.elevation2,
          borderBottomWidth: 1,
          justifyContent: 'space-between',
        },
        containerSelected: {
          borderRadius: theme.radius.m,
          backgroundColor: colors.text,
        },
        sortOptionTitle: {
          fontSize: scale(14),
          lineHeight: scale(22),
          color: colors.text,
        },
        sortOptionTitleChecked: {
          color: colors.textInverted,
        },
        subtitle: {
          fontSize: scale(14),
          lineHeight: scale(22),
          color: colors.textLight,
        },
        action: {
          justifyContent: 'center',
        },
        label: {
          fontSize: scale(14),
          lineHeight: scale(22),
          textAlign: 'center',
          fontFamily: theme.fonts.medium,
        },
        accessory: {
          flexDirection: 'row',
          alignItems: 'center',
        },
      }),
    [colors, scale],
  );
};

export default FiltersTabStyles;
