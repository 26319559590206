import {
  Docket,
  DocketItem,
  Printer,
  PrinterType,
  PrintingOptions,
} from '@oolio-group/domain';
import { Session } from '../state/preferences';

export enum DocketType {
  CANCEL_DOCKET = 'CANCEL_DOCKET',
  RESEND_DOCKET = 'RESEND_DOCKET',
  KITCHEN_DOCKET = 'KITCHEN_DOCKET',
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export enum WorkerAction {
  // print actions
  PRINT_KITCHEN_DOCKET = 'PRINT_KITCHEN_DOCKET',
}

export enum WorkerActionResultStatus {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export type WorkerActionHandlers = {
  [action: string]: (message: WorkerInput) => Promise<WorkerActionResult[]>;
};

export interface WorkerActionResult {
  requestId?: string;
  status?: WorkerActionResultStatus;
  data?: WorkerActionResultData;
  message?: string;
  action?: WorkerAction;
  timestamp?: number;
  processed?: boolean;
}

// can add other types as union
type WorkerActionResultData = PrintWorkerActionData;

export interface PrintWorkerActionData {
  buffer?: Buffer;
  printer?: WebPrinter;
  docket?: Docket;
  errorMessage?: string;
}

type PrintWorkerInput = PrintWorkerInputBase | PrintKitchenDocketWorkerInput;

export interface WorkerInput {
  action: WorkerAction;
  requestId?: string;
  // can add other types as union
  data: PrintWorkerInput;
  priority?: number;
}

export interface PrintWorkerInputBase {
  bufferObjs?: {
    buffer: Buffer;
    printer: WebPrinter;
  }[];
}
export interface PrintKitchenDocketWorkerInput extends PrintWorkerInputBase {
  docket: Docket;
  session: Session;
  docketItems: DocketItem[];
  translatedNowCourse: string;
  printingOptions: PrintingOptions;
}

// buffer handlers
export type BufferHandlers = {
  [action: string]: (message: WorkerInput) => BufferHandlerResult[];
};

export interface WebPrinter extends Partial<Printer> {
  device_name: string;
  host?: string;
  type: PrinterType;
  id: string;
  port: number;
}

export interface BufferHandlerResult {
  printer: WebPrinter;
  buffer: Buffer;
}
export const DEFAULT_NET_PRINTER_PORT = 9100;
