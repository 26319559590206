import React, { useLayoutEffect, useRef, useState } from 'react';
import { LayoutChangeEvent, View, useWindowDimensions } from 'react-native';
import { DocketItemStatus, DocketStatus } from '@oolio-group/domain';
import { useScale } from '../../../hooks/useScale';
import { useSession } from '../../../hooks/useSession';
import { useDockets } from '../../../hooks/useDockets';
import { useOrderTypes } from '../../../hooks/useOrderTypes';
import { useSalesChannels } from '../../../hooks/useSalesChannels';
import DocketItem from '../../../component/DocketItem/DocketItem';
import { PaginatedDocketItems } from '../../../utils/layoutHelper';
import MainScreenStyles from '../MainScreen.styles';
import { DOCKET_WIDTH } from '../../../common/constants';

export interface DocketsGridProps {
  displayDocketItems: PaginatedDocketItems[];
  status: DocketStatus;
  currentPage: number;
  enablePrint?: boolean;
  setColumnsHeight?: (height: number) => void;
  columnsHeight?: number;
}

const DocketsGrid: React.FC<DocketsGridProps> = ({
  displayDocketItems,
  status,
  currentPage,
  enablePrint,
  setColumnsHeight,
  columnsHeight,
}) => {
  const {
    bumpDocketWithStatus,
    recallDocket,
    bumpDocketItem,
    recallDocketItem,
    serveDocketItem,
    reBumpDocketItem,
    printKitchenDocket,
  } = useDockets();
  const { scale } = useScale();
  const { session } = useSession();
  const styles = MainScreenStyles();
  const { orderTypes } = useOrderTypes();
  const { width } = useWindowDimensions();
  const { salesChannels } = useSalesChannels();

  const [itemWidth, setItemWidth] = useState<number>(0);
  const heightRef = useRef<any>(undefined);

  useLayoutEffect(() => {
    const columnsPossible = Math.floor(width / scale(DOCKET_WIDTH));
    setItemWidth(Math.floor(width / columnsPossible - scale(12)));
  }, [scale, width]);

  const handleOnPressDocketHeader = async (docketId: string) => {
    if (status == DocketStatus.CREATED)
      return bumpDocketWithStatus(
        docketId,
        session?.rebumpToggle
          ? DocketItemStatus.REBUMPED
          : DocketItemStatus.BUMPED,
      );
    else return recallDocket(docketId);
  };

  const handleOnPressDocketItem = async (
    docketItemId: string,
    itemStatus: DocketItemStatus,
  ) => {
    if (
      itemStatus == DocketItemStatus.CREATED ||
      itemStatus == DocketItemStatus.RECALLED
    ) {
      await bumpDocketItem(docketItemId);
    } else if (
      status == DocketStatus.CREATED &&
      itemStatus == DocketItemStatus.BUMPED
    ) {
      session?.rebumpToggle
        ? await reBumpDocketItem(docketItemId)
        : await serveDocketItem(docketItemId);
    } else if (
      status == DocketStatus.CREATED &&
      itemStatus == DocketItemStatus.REBUMPED
    ) {
      await serveDocketItem(docketItemId);
    } else {
      await recallDocketItem(docketItemId);
    }
  };
  const onLayout = (event: LayoutChangeEvent) => {
    const { height } = event.nativeEvent.layout;
    if (!columnsHeight || columnsHeight <= 0) {
      setColumnsHeight?.(height);
    }
  };

  return (
    <View style={styles.grid} ref={heightRef} onLayout={onLayout}>
      {displayDocketItems[currentPage]?.columns.map((col, index) => {
        return (
          <View key={index} style={styles.gridColumn}>
            {col.items.map((item, i) => {
              return (
                <View key={i} style={[styles.item, { width: itemWidth }]}>
                  <DocketItem
                    key={item.name + i}
                    item={item}
                    status={status}
                    orderTypes={orderTypes}
                    salesChannels={salesChannels}
                    onPressDocketHeader={handleOnPressDocketHeader}
                    onPressDocketItem={handleOnPressDocketItem}
                    enablePrint={enablePrint}
                    printKitchenDocket={printKitchenDocket}
                  />
                </View>
              );
            })}
          </View>
        );
      })}
    </View>
  );
};

export default DocketsGrid;
