import { useEffect, useState } from 'react';
import { BehaviorSubject, Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';

export interface Notification {
  id?: symbol;
  error?: boolean;
  success?: boolean;
  info?: boolean;
  message: string;
}

export const notificationObserver = new BehaviorSubject<Notification | null>(
  null,
);

const DEFAULT_TTL = 3000; // in ms

class NotificationQueue {
  itemsPending: Array<Notification> = [];
  queue: BehaviorSubject<Notification | null> =
    new BehaviorSubject<Notification | null>(null);

  constructor() {
    this.setupQueueProcessor();
  }

  enqueue(notification: Notification) {
    if (this.itemsPending.length === 0) {
      this.queue.next(notification);
    }
    this.itemsPending.push(notification);
  }

  private setupQueueProcessor() {
    this.queue
      .pipe(concatMap(notification => this.processNotification(notification)))
      .subscribe();
  }

  clearFromQueue(id: symbol) {
    this.itemsPending = this.itemsPending.filter(
      notification => notification.id !== id,
    );
    if (this.itemsPending.length === 0) {
      this.queue.next(null);
    } else {
      this.queue.next(this.itemsPending[0]);
    }
  }

  private processNotification(
    notification: Notification | null,
  ): Observable<Notification | null> {
    return new Observable<Notification | null>(observer => {
      if (!notification) {
        observer.next(null);
        observer.complete();
        return;
      }
      setTimeout(() => {
        this.clearFromQueue(notification.id as symbol);
        observer.complete();
      }, DEFAULT_TTL);
    });
  }
}

export const notificationQueue = new NotificationQueue();

export const sendNotification = (notification: Notification) => {
  if (!notification.id) {
    notification.id = Symbol();
  }
  notificationQueue.enqueue(notification);
};

export const useNotification = () => {
  const [currentNotification, setCurrentNotification] =
    useState<Notification | null>(null);

  useEffect(() => {
    const subscription = notificationQueue.queue.subscribe(notification => {
      if (!notification) {
        setCurrentNotification(null);
      } else {
        setCurrentNotification(notification);
      }
    });
    return () => subscription.unsubscribe();
  }, []);

  function closeNotification(id: symbol | undefined) {
    if (!id) return;
    setCurrentNotification(null);
  }

  return {
    closeNotification,
    currentNotification,
  };
};
