import React from 'react';
import { View, Text } from 'react-native';
import DocketItemStyles from '../DocketItem.styles';

export interface TrainingDocketHeaderProps {
  headerText: string;
}

const TrainingDocketHeader: React.FC<TrainingDocketHeaderProps> = ({
  headerText,
}) => {
  const styles = DocketItemStyles();

  return (
    <View testID="docket-training" style={styles.trainingHeader}>
      <Text style={[styles.text, styles.trainingText]}>{headerText}</Text>
    </View>
  );
};

export default TrainingDocketHeader;
